import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Online from './pages/online';
import Product from'./pages/product';
import Category from'./pages/category';
import Offline from './pages/offline';
import Offlinecategory from './pages/offlinecategory';
import Offlineproduct from './pages/offlineproduct';
import Addcart from './pages/addcart';
import Checkout from './pages/checkout';
import Processsucess from './pages/processsucess';
import Track from './pages/track';
import Wishlist from './pages/wishlist';
import Login from './pages/login';
import Register from './pages/register';
import Test from './pages/test';
import Orders from './pages/ordersdetail';
import Account from './pages/account';
import About from './pages/about';
import Contact from './pages/contact';
import Search from './pages/search';
import Privacy from './pages/privacy-policy';
import Terms from './pages/terms';
import Preorder from './pages/preorder';
import Preorderproduct from './pages/preorderproduct';
import Payment from './pages/payment';
import Paymentstatus from './pages/paymentstatus';
import Invoice from './pages/invoice';
import Otp from './pages/otp';
import PhonePe from './pages/phonePe';
import LSProduct from './pages/lsProduct';




function App() {
  return (
   <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route  path="/home" element={<Home />} />
      <Route  path="/online-products" element={<Online />} />
      <Route  path="/product" element={<Product />} />
      <Route  path="/category" element={<Category/>} />
      <Route  path="/offline" element={<Offline/>} />
      <Route  path="/offlineproduct" element={<Offlineproduct/>} />
      <Route  path="/offlinecategory" element={<Offlinecategory/>} />
      <Route  path="/addcart" element={<Addcart/>} />
      <Route  path="/checkout" element={<Checkout />} />
      <Route  path="/checkout-phonepe" element={<PhonePe />} />
      <Route  path="/processsucess" element={<Processsucess/>} />
      <Route  path="/track" element={<Track/>} />
      <Route  path="/wishlist" element={<Wishlist/>} />
      <Route  path="/login" element={<Login/>} />
      <Route  path="/register" element={<Register/>} />
      <Route  path="/account" element={<Account />} />
      <Route  path="/ordersdetail" element={<Orders />} />
      <Route  path="/test" element={<Test />} />
      <Route  path="/about" element={<About />} />
      <Route  path="/contact" element={<Contact />} />
      <Route  path="/search" element={<Search />} />
      <Route  path="/privacy" element={<Privacy />} />
      <Route  path="/terms" element={<Terms />} />
      <Route  path="/live-shopping" element={<Preorder />} />
      <Route  path="/preorderproduct" element={<Preorderproduct />} />
      <Route  path="/payment" element={<Payment />} />
      <Route  path="/paymentstatus" element={<Paymentstatus />} />
      <Route  path="/Invoice" element={<Invoice />} />
      <Route  path="/otp" element={<Otp />} />
      <Route  path="/live-shopping-product" element={<LSProduct />} />
    </Routes>
   </Router>
  );
}

export default App;