import React, { useEffect, useState } from 'react';
import Header from './headerpages';
import Footer from './footer';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { useNavigate} from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import HomeComponent from './invoice';
ReactSession.setStoreType("localStorage");

 function Track() {
  
    const navigate = useNavigate();
    const [process, setProcess] = useState([]);
    const [preorderprocess, setpreorderProcess] = useState([]);
    const [ordertracking, setorderTracking] = useState([]);
    const [preordertracking, setpreorderTracking] = useState([]);
    const queryParameters = new URLSearchParams(window.location.search)
    const orderId = queryParameters.get("id");
    const orderFrom = queryParameters.get("order");
    sessionStorage.setItem('id', orderId);

    const userId = ReactSession.get("arkuserId");
    const otpStatus = ReactSession.get("otp");

    if(userId > 0 && otpStatus == 'no'){
      navigate('/test');
    }


    const fetchData = async () => {
      try {
        const response = await axios.post(`https://api.arivomkadai.com/processsucess/`, {id:orderId});
        setProcess(response.data.data[0]);
        //console.log(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    

    const fetchDatatrack = async () => {
     try {
       const formData = {id:orderId};
       const response = await axios.post(`https://api.arivomkadai.com/order_tracking/`, formData);
       setorderTracking(response.data);
     } catch (error) {
       console.error("Error fetching data:", error);
     }
    };

    const fetchpreorderData = async () => {
       try {
         const formData = {id:orderId};
         const response = await axios.post(`https://api.arivomkadai.com/proordersucess/`, formData);
         setpreorderProcess(response.data.data);
       } catch (error) {
         console.error("Error fetching data:", error);
       }
    };


    const fetchpreorderDatatrack = async () => {
      try {
        const formData = {id:orderId};
        const response = await axios.post(`https://api.arivomkadai.com/preorder_tracking/`, formData);
        setpreorderTracking(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

   useEffect(() => {
    fetchpreorderDatatrack();
    fetchpreorderData();
    fetchDatatrack();
    fetchData();
   }, []); 

     
  const handleDownloadPDF = async () => {
   // openModal();
    const input = document.getElementById('pdf-content');
    input.classList.remove('hide');
  
    const pdf = new jsPDF({
      orientation: 'portrait', // Use 'portrait' or 'landscape' as per your requirement
      unit: 'pt', // Set unit to millimeters
      format: 'a4', // Set format to A4
    });
    const inputHeight = input.clientHeight;
    const pageHeight = pdf.internal.pageSize.getHeight()+600;
    let currentPosition = 0;
    const scaleFactor = 4; 
    
    // Increase this value for higher resolution and better quality
  
    while (currentPosition < inputHeight) {
      const canvas = await html2canvas(input, {
        height: Math.min(pageHeight),
        
        y: currentPosition,
        scrollY: -currentPosition,
        scale: scaleFactor
      });

      

      
      const imgData = canvas.toDataURL('image/png', 4);
      pdf.addImage(imgData, 'PNG', 0, 0, 580, pdf.internal.pageSize.getHeight(), '', 'FAST');
      //currentPosition +=canvas.height;
      currentPosition += pageHeight;
      if (currentPosition < inputHeight) {
        pdf.addPage();
      }
     
    }
    pdf.save(orderId);
    
    input.classList.add('hide');
   // closeModal();
            sessionStorage.removeItem('id');
  };

  console.log(preorderprocess);
    

  return (
    <>
    <div class="sidebars">
    <a  className="sidebars" href="/online-products">
      <h3 class="bar-content">Arivom Products </h3>
    </a>
</div>
<div class="main-bar">

  <Header  />

  <main class="main">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a>
                    <span></span> Track Order 
                </div>
            </div>
        </div>
        <div class="page-header mt-25 mb-10">
          <div class="container">
            <div class="archive-headers">
                {orderFrom === 'live-shopping' ? (
                  <div class="row align-items-center">
                    <>
                      <div class="col-xl-12" align="center">
                        { preorderprocess?.status === "Order Placed" ? 
                              <img src="assets/svg/check.svg" alt="" width="60px" /> 
                          : preorderprocess?.status === "Placed" ? 
                              <img src="assets/svg/check.svg" alt="" width="60px" /> 
                          : preorderprocess?.status === "Shipped" ?
                              <img src="assets/svg/shipping.svg" alt="" width="60px" />
                          : preorderprocess?.status === "Delivered" ?
                              <img src="assets/svg/delivery.svg" alt="" width="60px" />
                          : preorderprocess?.status === "Cancelled" && 
                              <img src="assets/svg/cancelled.png" alt="" width="60px" />
                        }
                      </div>
                      <div class="col-xl-12" style={{ textAlignLast: "center" }}>
                        <h1 class="mb-5">{preorderprocess?.status}</h1>
                        <h5 class="font-light">{orderId}</h5>
                      </div>
                    </>
                  </div>
 
                )
                :(<>
                    <div class="row align-items-center">
                      {process && process.payment_status === "paid" ? (
      <>
        {process.status === "Order Placed" && (
          <>
             <div class="col-xl-12" align="center">
              <img src="assets/svg/packing.png" alt="" class="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4 mt-40" width="100px" />
            </div>
            <div class="col-xl-12" style={{textAlignLast: "center"}}>
              <h3 class="mb-5" style={{color:"darkgreen"}}>Order Placed.</h3>
              <h5 class="font-light">{orderId}</h5>
            </div>
          </>
        )}
        {process.status === "Confirm" && (
          <>
             <div class="col-xl-12" align="center">
              <img src="assets/svg/packing.png" alt="" class="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4 mt-40" width="100px" />
            </div>
            <div class="col-xl-12" style={{textAlignLast: "center"}}>
              <h3 class="mb-5" style={{color:"darkgreen"}}>Order Placed.</h3>
              <h5 class="font-light">{orderId}</h5>
            </div>
          </>
        )}
        {process.status === "Dispatched" && (
          <>
            <div class="col-xl-12" align="center">
              <img src="assets/svg/shipped.png" alt="" width="100px" />
            </div>
            <div class="col-xl-12" style={{textAlignLast: "center"}}>
              <h3 class="mb-5" style={{color:"darkblue"}}>Order Shipped.</h3>
              <h5 class="font-light">{orderId}</h5>
            </div>
          </>
        )}
        {process.status === "Delivered" && (
          <>
             <div class="col-xl-12" align="center">
              <img src="assets/svg/delivered.png" alt="" width="100px" />
            </div>
            <div class="col-xl-12" style={{textAlignLast: "center"}}>
              <h3 class="mb-5"  style={{color:'darkorange'}}>Order Delivered.</h3>
              <h5 class="font-light">{orderId}</h5>
            </div>
          </>
        )}
        {process.status === "Cancelled" && (
          <>
            <div class="col-xl-12" align="center">
              <img src="assets/svg/cancelled.png" alt="" width="100px" />
            </div>
            <div class="col-xl-12" style={{textAlignLast: "center"}}>
              <h3 class="mb-5"  style={{color:"red"}}>Order Cancelled.</h3>
              <h5 class="font-light">{orderId}</h5>
            </div>
          </>
        )}
      </>
    ) : (
      <>
        <div class="col-xl-12" align="center">
          <img src="assets/svg/cancelled.png" alt="" width="100px" />
        </div>
        <div class="col-xl-12" style={{textAlignLast: "center"}}>
          <h3 class="mb-5" style={{color:"red"}}>Payment Failed.</h3>
          <h5 class="font-light">{orderId}</h5>
        </div>
      </>
    )}
  </div>
</>)}
               
                   
                </div>
            </div>
        </div>

       





        <div class="container mb-80 mt-50">



              {orderFrom === 'live-shopping' ? (
                <div class="row">
       
                    <div class="col-lg-8">
                    
                    <div class="table-responsive shopping-summery">
                        <table class="table table-wishlist">
                            <thead>
                                <tr class="main-heading">
                                    <th class="custome-checkbox start pl-30">
                                       
                                    </th>
                                    <th scope="col" colspan="2">Product</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">qty</th>
                                    <th scope="col">Subtotal</th>
                                </tr>
                            </thead>
                            <tbody>
                            {preorderprocess?.orderdetails?.map(product => (
                    <tr className="pt-30"key={product.id}>
                        <td className="custome-checkbox pl-30">
                          
                        </td>
                        <td className="image product-thumbnail pt-40">
                            <img src={`https://api.arivomkadai.com/product_data/${product.img}`}  alt={product.product}/>
                        </td>
                        <td className="product-des product-name pr-50">
                            <h6 className="mb-5"><a className='product-name mb-10 text-heading' >{product.product.length > 50 ? `${product.product.slice(0, 50)}...` : product.product}
                            <p class="font-xxs">{product.code}</p></a></h6>
                        </td>
                        <td className="price" data-title="Price">
                            <h4 className="text-body">₹{product.price}</h4>
                        </td>
                        <td className="text-center detail-info" data-title="Stock">
                            <h4 className="text-body">x{product.quantity}</h4>
                        </td>
                        <td className="price" data-title="Price">
                            <h4 className="text-brand">₹{product.subtotal}</h4>
                        </td>
                    </tr>
                ))}
            </tbody>
            
            
                        </table>
                    </div>
                    <div class="divider-2 mb-30"></div>
                   
                </div>
                <div class="col-lg-4">
                    <div class="border p-md-4 cart-totals ml-30">
                   
                        <div class="table-responsive">
                            <table class="table no-border">
                            <tbody>
                 
                            <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Subtotal</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h4 class="text-brand text-end">₹ {Number(preorderprocess?.total).toFixed(2)}</h4>
                                </td>
                            </tr>
                            <tr>
                                <td scope="col" colspan="2">
                                    <div class="divider-2 mt-10 mb-10"></div>
                                </td>
                            </tr>
                            <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Shipping</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h5 class="text-heading text-end">₹{Number(preorderprocess?.shipping).toFixed(2)}</h5>
                                </td>
                            </tr>
                            <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Coupon Discount</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h5 class="text-heading text-danger text-end">- ₹{Number(preorderprocess?.offer).toFixed(2) || 0.00}</h5>
                                </td>
                            </tr>

                            {preorderprocess?.gift > 0 ? (<> <tr>
                             <td class="cart_total_label">
                             <h6 class="text-muted">GiftBox</h6>
                             </td>
                             <td class="cart_total_amount">
                                 <h4 class="text-brand text-end">₹{Number(preorderprocess?.gift).toFixed(2)}</h4>
                             </td>
                         </tr></>):(<></>)} 
                            <tr>
                                <td scope="col" colspan="2">
                                    <div class="divider-2 mt-10 mb-10"></div>
                                </td>
                            </tr>
                            <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Total</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h4 class="text-brand text-end">₹{Number(preorderprocess?.grandtotal).toFixed(2) || 0}.00</h4>
                                </td>
                            </tr>
                        
                
                </tbody>
                            </table>

                            <h5>Shipping Details</h5>
               
               <div class="tab-pane fade active show mt-15"  id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                <div class="loop-grid loop-list  mb-50" >
                   <article class="wow fadeIn animated hover-up mb-30 animated">
                     <div class="entry-content-2 pl-50">
                     
               
                       <p>Order Id : {preorderprocess?.id}</p>
               
                        <p>Placed on : {preorderprocess?.date}</p>

                        <p>
                          {preorderprocess?.name}<br />
                          {preorderprocess?.building}, {preorderprocess?.area} <br />
                          {preorderprocess?.city},{preorderprocess?.state} - {preorderprocess?.pincode} <br />
                          {preorderprocess?.email} <br />
                          {preorderprocess?.phonenumber}
                        </p>
             
                     </div>
                    </article>
                 </div>
                 <h5>Order Tracking</h5>

                 <div class="loop-grid loop-list  mb-50" >
                   <article class="wow fadeIn animated hover-up mb-30 animated">
                     <div class="entry-content-2 pl-50">
                       <span>Order ID : <span>{preordertracking && preordertracking?.order_id}</span></span><br></br>
               
                        <ul class='order-summery'>
                          
                          {preordertracking && preordertracking.status && preordertracking?.status.map((statusObj, index) => (
                              
                            <li key={index} className="post-excerpt">
                              {statusObj.order_time} - {statusObj.status}
                            </li>
                          ))}
                        </ul>
                      
                     </div>
                    </article>
                 </div>
                 
                 <HomeComponent id="pdf-content"/>
				 {preorderprocess?.payment_status === "paid" ? (
                 <button
                onClick={handleDownloadPDF}
      className={`btn4 btn-primary`}
      style={{  marginLeft:'100px'}}
    >
     Download Invoice
				 </button>):''}
               </div>
                        </div>
                        </div>
                </div>
            </div>
            ):
            (
                <div class="row">
       
    <div class="col-lg-8">
        
        <div class="table-responsive shopping-summery">
            <table class="table table-wishlist">
                <thead>
                    <tr class="main-heading">
                        <th class="custome-checkbox start pl-30">
                           
                        </th>
                        <th scope="col" colspan="2">Product</th>
                        <th scope="col">Price</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                {process && process.orderData && process.orderData.map(product => (
        <tr className="pt-30"key={product.id}>
            <td className="pl-10">
              
            </td>
            <td className="image product-thumbnail">
                <img src={`https://api.arivomkadai.com/product_data/${product.img}`} width="70px" alt={product.product}/>
            </td>
            <td className="product-des product-name pr-50">
                <h6 className="mb-5"><a className='product-name mb-10 text-heading' >{product.product.length > 50 ? `${product.product.slice(0, 50)}...` : product.product}<br></br>
                <p class="font-xxs">{product.code} | {product.varient}</p></a></h6>
            </td>
            <td className="price" width="10%">
                <h6 className="">₹ {Number(product.price).toFixed(2)}</h6>
            </td>
            <td className="price" width="7%">
                <h6 className="">x {product.quantity}</h6>
            </td>
            <td className="price">
                <h4 className="text-brand">₹ {Number(product.price * product.quantity).toFixed(2)}</h4>
            </td>
        </tr>
    ))}
</tbody>


            </table>
        </div>
        <div class="divider-2 mb-30"></div>
       
    </div>
    <div class="col-lg-4">
        <div class="border p-md-4 cart-totals ml-30">
       
            <div class="table-responsive">
                <table class="table no-border">
                <tbody>
     
                <tr>
                    <td class="cart_total_label">
                        <h6 class="text-muted">Sub Total</h6>
                    </td>
                    <td class="cart_total_amount">
                        <h4 class="text-brand text-end">₹ {Number(process && process.total).toFixed(2)}</h4>
                    </td>
                </tr>
              
                <tr>
                    <td class="cart_total_label">
                        <h6 class="text-muted">Shipping</h6>
                    </td>
                    <td class="cart_total_amount">
                        <h5 class="text-heading text-end">₹{Number(process && process.shipping).toFixed(2)}</h5>
                    </td>
                </tr>
                <tr>
                    <td class="cart_total_label">
                        <h6 class="text-muted">Coupon Discount</h6>
                    </td>
                    <td class="cart_total_amount">
                        <h5 class="text-heading text-danger text-end">- ₹{Number(process && process.discount).toFixed(2) || 0.00}</h5>
                    </td>
                </tr>


                {process && process.gift > 0 ? (<> <tr>
                             <td class="cart_total_label">
                             <h6 class="text-muted">GiftBox</h6>
                             </td>
                             <td class="cart_total_amount">
                                 <h4 class="text-heading text-end">₹{Number(process && process.gift).toFixed(2)}</h4>
                             </td>
                         </tr></>):(<></>)}
                <tr>
                    <td scope="col" colspan="2">
                        <div class="divider-2 mt-10 mb-10"></div>
                    </td>
                </tr>
                <tr>
                    <td class="cart_total_label">
                        <h6 class="text-muted">Total</h6>
                    </td>
                    <td class="cart_total_amount">
                        <h4 class="text-brand text-end">₹{Number(process && process.grand_total).toFixed(2) || 0.00}</h4>
                    </td>
                </tr>
            
    
    </tbody>
                </table>

                <h5>Shipping Details</h5>
               
               <div class="tab-pane fade active show mt-15"  id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                <div class="loop-grid loop-list  mb-50" >
                   <article class="wow fadeIn animated hover-up mb-30 animated">
                     <div class="entry-content-2 pl-50">
                        <p>Order Id : {process && process.order_id}</p>
                        <p>Invoice Id : #000{process && process.invoiceNumber}</p>
                        <p>Placed On : {process && process.date}</p> <br />
                        
                        <p><i class="fa fa-home pr-10"></i> Address<br /> <br/>
                          {process && process.name} <br />
                          {process && process.buiulding} {process && process.street},
                          {process && process.city}, {process && process.state} - {process && process.pincode} <br />
                          
                        </p> <br />
                        <p><i class="fa fa-phone  pr-10"></i>{process && process.number}</p>
                        <p><i class="fa fa-envelope pr-10"></i>{process && process.email}</p>
                         
                     </div>
                    </article>
                 </div>
				 
				   
       

           {process && process.courier !== 'no_data' &&(
                 <>
                 <h5 >Tracking Details</h5>
                       <div class="tab-pane fade active show mt-10"  id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                            <div class="loop-grid loop-list  mb-50" >
                               <article class="wow fadeIn animated hover-up mb-30 animated">
                                 <div class="entry-content-2 pl-50">
                                 <span>Courier : <span>{process && process.courier}</span></span><br></br>
                                   <span>Tracking ID : <span>{process && process.awno}</span></span><br></br>
                          
                                </div>
                                </article>
                              </div>
                        </div>
                 </>
                )}

                 <h5>Order Tracking</h5><br />

<div class="loop-grid loop-list  mb-50" >
  <article class="wow fadeIn animated hover-up mb-30 animated">
    <div class="entry-content-2 pl-50">
    

       <ul class='order-summery'>
        
       <li>
                          {ordertracking && ordertracking.data && ordertracking.data.status.map((statusObj, index) => (
                              
    <p key={index} className="post-excerpt">
      <span><small class="font-xs">{statusObj.order_time}</small> - {statusObj.status}
        </span>
    </p>
   
))}
                          </li>
   
        
   
       </ul>
       

    </div>
   </article>
</div>



                 <HomeComponent id="pdf-content"/>
				  {process && process.payment_status === "paid" ? (
                 <button
                onClick={handleDownloadPDF}
      className={`btn4 btn-primary`}
      style={{  marginLeft:'100px'}}
    >
     Download Invoice
				  </button>):''}
               </div>
            </div>
          </div>
    </div>
</div>
            )}
        

</div>
    </main>

  <Footer />

  </div>
  </>
  )
}

export default Track;