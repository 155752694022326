import React from 'react';
import Header from './headerpage';
import Headers from './headerpages';
import Footer from './footer';
import Footers from './footeroffline';
import Slider from './slider';


function Contact () {
     
    const queryParameters = new URLSearchParams(window.location.search)
    const contact = queryParameters.get("id") 
    return [
        <>
        
        {contact === '1' ? (
  <>
    <div class="sidebars">
      <a href="/offline">
        <h3 class="bar-content">Arivom Products</h3>
      </a>
    </div>
  
  </>
) : (
  <>
    <div class="sidebars-on">
   
      <a href="/online-products">
        <h3 class="bar-content">Online Products</h3>
      </a>
    </div>
  </>
)}
      <div class="main-bar">
      {contact === '1' ? (<Header  />):(<Headers  />)}
       
        <main class="main pages">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a> <span></span> Contact
                </div>
            </div>
        </div>
        <div class="page-content pt-50">
            
            
          
            <div class="container">
                <div class="row">
                    <div class="col-xl-10 col-lg-12 m-auto">
                        <section class="mb-50">
                            <div class="row">
                                <div class="col-xl-8">
                                    <div class="contact-from-area padding-20-row-col">
                                        <h5 class="text-brand mb-10">Contact Us</h5>
                                        <p class="text-muted mb-30 font-sm">Your email address will not be published. Required fields are marked *</p>
                                        <form class="contact-form-style mt-30" id="contact-form" action="#" method="post">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="input-style mb-20">
                                                        <input name="name" placeholder="Name" type="text" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="input-style mb-20">
                                                        <input name="email" placeholder="Email" type="email" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="input-style mb-20">
                                                        <input name="telephone" placeholder="Phone" type="tel" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="input-style mb-20">
                                                        <input name="subject" placeholder="Subject" type="text" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="textarea-style mb-30">
                                                        <textarea name="message" rows="2" placeholder="Message"></textarea>
                                                    </div>
                                                    <button class="submit submit-auto-width" type="submit">Send message</button>
                                                </div>
                                            </div>
                                        </form>
                                        <p class="form-messege"></p>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-4 mb-md-0">
                                        <h4 class="mb-15 mt-15 text-brand">Visit Us</h4>
                                        <p>Addess : 5D, R.P Jaya Paradise, Chitra Nagar, Opposite to Amman Kovil Saravanampatti, Coimbatore</p>
                                        <p>Pincode : 641035<br />
                                            Phone :  +91 90438 42020 | +91 81229 30840 <br />
                                            Email : arivomkadaiofficial@gmail.com  </p>
                                        {/* <a class="btn btn-sm font-weight-bold text-white mt-20 border-radius-5 btn-shadow-brand hover-up"><i class="fi-rs-marker mr-5"></i>View map</a> */}
                                </div>
                            </div>
                            
                        </section>

                        
                    </div>
                </div>
            </div>

            <section class="container mb-50 d-none d-md-block">
                <div class="border-radius-15 overflow-hidden">
                    <div id="map-panes" class="leaflet-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d125322.51346877182!2d76.88483359502347!3d11.013957788166207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859af2f971cb5%3A0x2fc1c81e183ed282!2sCoimbatore%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1716027964561!5m2!1sen!2sin" width="100%" height="100%" style={{ border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>

        </div>
    </main>
       
        <Slider/>
        {contact === '1' ? (<Footer />):(<Footers  />)}
    
        </div>
        </>
    ]
}
export default Contact;