import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './headerpages';
import Footer from './footeroffline';
import { ReactSession } from 'react-client-session';
import { useNavigate } from "react-router-dom";
import Footeroffline from './footeroffline';
import ContentEditable from 'react-contenteditable'
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Preorderproduct() {

    const navigate = useNavigate();
    const userId = ReactSession.get("arkuserId");
    const [productData, setProductData] = useState(null);
    const queryParameters = new URLSearchParams(window.location.search)
    const productId = queryParameters.get("id");
    const [isReadMore, setIsReadMore] = useState(true);

    const [addresses, setAddresses] = useState({});
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const [select, setSelect] = useState(false);
    const [qty, setQty] = useState(1);
    

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.post(`https://api.arivomkadai.com/live-product/view-product/`, {productId: productId});
            console.log(response.data);
            setProductData(response.data.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData();
    }, [productId]);

    

    const copyButtonClick = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
        alert('UPI copied to clipboard.');
    };
 

    const toggleDescription = () => {
        setIsReadMore(!isReadMore);
    };


    const fetchAddressData = async () => {
        const formData = {
            id: userId,
        };
    
        try {
            const response = await axios.post('https://api.arivomkadai.com/address/', formData);
            //console.log(response.data.data);
            const fetchedAddresses = response.data.data;
            setAddresses(fetchedAddresses);
          
            if (fetchedAddresses.length > 0) {
                const lastAddressId = fetchedAddresses[fetchedAddresses.length - 1].id;
                if(selectedAddressId === '' || selectedAddressId === null){
                    setSelectedAddressId(lastAddressId);
                }
            }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
    };
    
    useEffect(() => {
      fetchAddressData();
    }, []); 

    const addToCart = () => {
        
        if(!userId || parseFloat(userId) === 0 || userId === '' || userId === null){
            alert('Please login to continue!');
            navigate('/login');
        }else{
            fetchAddressData();
            setSelect(true);
        }
    };

    const placeOrder = async () => {
        const formData ={
            productId: productId,
            userId: userId,
            address: selectedAddressId,
            productPrice: productData?.productPrice,
            productQty: qty,
            productName: productData?.product_name,
            productCode: productData?.product_code,
            image: productData?.image,
            id: productData?.id
        };

        try{
            const response = await axios.post('https://api.arivomkadai.com/live-product/new-order/', formData);
            console.log(response.data);
            if(response.data.status === 'success'){
                alert(`Your order is placed with order ID ${response.data.orderId}`);
                navigate(`/track?id=${response.data.orderId}&order=live-shopping`);
            }

        } catch (error) {
          console.error("Error fetching data:", error);
        }
    };

    const changeQty = (type) => {
        if(type === 'plus'){
            if( productData?.stock >= qty+1){
                setQty(qty+1);
            }else{
                alert(`Only ${productData?.stock} is Stock available for ${productData?.product_name}`);
            }
        }else{
           if(qty > 1){
            setQty(qty-1);
           } 
        }

    };
    

    const imageUrl = `https://api.arivomkadai.com/product_data/${productData?.image}` || "https://arivomkadai.com/favicon.png";
    const productTitle = productData?.product_name || "ArivomKadai - We Make Your Shopping Simple";
    const productDescription = productData?.content || "ArivomKadai - We Make Your Shopping Simple";

  return (
       <>
  <HelmetProvider>   
    <Helmet>
      <title>{productTitle}</title>
      <link rel="canonical" href={`https://arivomkadai.com/preorderproduct?id=${productId}/`} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={productTitle} />
      <meta property="og:description" content={productDescription} />
      <meta property="og:url" content={`https://arivomkadai.com/preorderproduct?id=${productId}/`} />
      <meta property="og:site_name" content="ArivomKadai" />
      <meta property="og:updated_time" content="2024-08-17T13:12:25+00:00" />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:secure_url" content={imageUrl} />
      <meta property="og:image:alt" content={productTitle} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="article:published_time" content="2024-08-17T13:12:25+00:00" />
      <meta property="article:modified_time" content="2024-08-17T13:12:25+00:00" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={productTitle} />
      <meta name="twitter:description" content={productDescription} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:label1" content="Written by" />
      <meta name="twitter:data1" content="ArivomKadai" />
      <meta name="twitter:label2" content="Time to read" />
      <meta name="twitter:data2" content="2 minutes" />
    </Helmet>
    </HelmetProvider> 

  
    <div class="sidebars">
        <a  className="sidebars-on" href="/online-products">
	        <h3 class="bar-content">online Products </h3>
	    </a>
    </div>
    <div class="main-bar">

	<Header  />

    <main class="main mt-3 d-mt-10">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home {selectedAddressId}</a>
                    <span></span>
                      {productData?.product_name}
                </div>
            </div>
        </div>



        <div class="container mb-30">
            <div class="row">
                <div class="col-xl-12 col-lg-12 m-auto">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="product-detail accordion-detail">
                                <div class="row mb-50 mt-30">
                                    <div class="col-md-4 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                                        <div class="detail-gallery">
                                            <div class="product-image-slider" style={{contentVisibility:'visible'}}>
                                                <figure class="border-radius-10">
                                                    {productData?.image !== '' && 
                                                        <img src={`https://api.arivomkadai.com/product_data/${productData?.image}`}  alt={productData?.p_id} width='100%' /> 
                                                    }
                                                </figure>
                                                <figure class="border-radius-10">
                                                    {productData?.image1 !== 'no_image.webp' && 
                                                       <img src={`https://api.arivomkadai.com/product_data/${productData?.image1}`} alt={productData?.p_id} width='100%' /> 
                                                    }
                                                </figure>
                                                <figure class="border-radius-10">
                                                    {productData?.image2 !== 'no_image.webp' &&  
                                                        <img src={`https://api.arivomkadai.com/product_data/${productData?.image2}`} alt={productData?.p_id} width='100%' /> 
                                                    }
                                                </figure>

                                                <figure class="border-radius-10">
                                                    {productData?.image3 !== 'no_image.webp' && 
                                                            <img src={`https://api.arivomkadai.com/product_data/${productData?.image3}`} alt={productData?.p_id} width='100%' /> 
                                                    }

                                                </figure>
                                            </div>
                                  
                                            <div class="slider-nav-thumbnails">
                                                <div>
                                                    {productData?.image !== '' && 
                                                       <img src={`https://api.arivomkadai.com/product_data/${productData?.image}`} alt={productData?.p_id} /> 
                                                    }
                                                </div>
                                                <div>
                                                    {productData?.image1 !== 'no_image.webp' && 
                                                       <img src={`https://api.arivomkadai.com/product_data/${productData?.image1}`} alt={productData?.p_id} /> 
                                                    }
                                                </div>
                                                <div>
                                                    {productData?.image2 !== 'no_image.webp' &&  
                                                       <img src={`https://api.arivomkadai.com/product_data/${productData?.image2}`} alt={productData?.p_id} /> 
                                                    }
                                                </div>
                                                <div>
                                                    {productData?.image3 !== 'no_image.webp' &&
                                                       <img src={`https://api.arivomkadai.com/product_data/${productData?.image3}`} alt={productData?.p_id} /> 
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                     
                                    </div>
                                    <div class="col-md-8 col-sm-12 col-xs-12">
                                        
                                        <div class="detail-info pr-30 pl-30">
                                            Product Code : <span class="stock-status out-stock"> {productData?.product_code}</span>
                                            <h2 class="title-detail">{productData?.product_name}</h2>
                                            <div class="short-desc mb-30">
                                                <p className="font-lg description"  id="disc">
                                                    {productData?.product_description &&(
                                                        <span>
                                                            {isReadMore
                                                                ?  <ContentEditable html={`${productData?.product_description.slice(0, 200)}`}  style={{ listStyleType: 'decimal' }}/>
                                                                :  <ContentEditable html={`${productData?.product_description}`}  style={{ listStyleType: 'decimal' }}/>}
                                                                {productData?.product_description.length > 200 && (
                                                                    <button id="readMoreBtn" onClick={toggleDescription}>
                                                                        {isReadMore ? '...read more' : ' show less'}
                                                                    </button>
                                                                )}
                                                        </span>
                                                    )}
                                                </p>
                                            </div>

                                            <div class="clearfix product-price-cover">
                                                <div class="product-price primary-color float-left">
                                                    <span class="current-price text-brand">₹ {parseFloat(productData?.productPrice).toFixed(2)}</span>
                                                </div>
                                            </div>

                                            <span class="stock-status out-stock"> {productData?.stock > 0 ? `${productData?.stock} stock only left` : `Out of stock` }</span>
                                            {productData?.stock > 0 ?

                                            !select ?
                                           
                                            <div align="center" >
                                                {productData?.qr &&
                                                    <img src={`https://api.arivomkadai.com/product_data/${productData?.qr}`} width="400px" />
                                                }

                                                <p class="mb-10">{productData?.upi} 
                                                    <button class="mr-10 mt-10" style={{padding: '20px', background: 'transparent', border: 'none'}} onClick={() => copyButtonClick(productData?.upi)}>
                                                        <i class="fa-regular fa-copy"></i>
                                                    </button>
                                                </p>

                                                <div class="detail-extralink ">
                                                    <button className="btn1" onClick={()=> changeQty('minus')}>-</button>
                                                    <div className="detail-qty border radius">
                                                        <input type="number" name="quantity" className="qty-val" min="1" step="1" value={qty} onChange={(e)=> {setQty(e.target.value)}} />
                                                    </div>
                                                    <button className="btn1" onClick={()=> changeQty('plus')}>+ </button>
                                                </div>

                                               
                                                <div class="primary-color text-bold">
                                                    <h4 class="current-price text-brand mt-3">₹ {(parseFloat(productData?.productPrice) * parseFloat(qty) ).toFixed(2)}</h4>
                                                </div>
                                           

                                                <p class="mt-20 mb-20"> Pay using QR Code/UPI and click Paid Button!</p>
                                            
                                                <div className="product-extra-link2">
                                                    <button onClick={()=> addToCart()} className="button button-add-to-cart">
                                                        I've Paid for this Product
                                                    </button>
                                                </div>
                                            </div>

                                            :

                                            <div class="row mt-50">
                                                {addresses?.map((address, index) =>
                                                    <div class="col-md-6 mt-10">
                                                        <div style={{border: '1px solid #ececec', padding: '20px', borderRadius: '10px'}}>
                                                            <p>
                                                                <b>{address.name}</b>  </p>
                                                                <small>{address.building}, {address.street} <br/>
                                                                {address.city}, {address.state} - {address.pincode} <br/>
                                                                <i class="fa-solid fa-envelope mr-10"></i> {address.email} <br/>
                                                                <i class="fa-solid fa-phone mr-10"></i> {address.phonenumber}
                                                                </small>
                                                           
                                                            <div class="btn-lft-align mt-2 mb-2">
                                                                {selectedAddressId === address.id ? (
                                                                    <button class="btn4 btn-primary">Selected</button>
                                                                    ):(
                                                                    <button class="btn3 btn-primary" onClick={()=> setSelectedAddressId(address.id)}>Select</button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                    <div class="col-md-6 mt-10" align="center">
                                                        <div style={{border: '1px solid #ececec', padding: '20px', borderRadius: '10px'}}>
                                                            <a class="btn3 btn-primary mt-20 mb-20" href="/account">Add Address</a>
                                                        </div>    
                                                    </div> 
                                                    {selectedAddressId && 
                                                    <div class="col-md-12 mt-10" align="center">
                                                        <div style={{border: '1px solid #ececec', padding: '20px', borderRadius: '10px'}}>
                                                            <button onClick={()=> {placeOrder()}} class="button mt-20 mb-20 w-100" style={{maxWidth: '400px'}} >Place Order</button>
                                                        </div>    
                                                    </div>     
                                                    }

                                            </div>    
                                            :
                                            <h6 class=" text-danger" style={{margin: '10px'}}>Sorry, this item is currently out of stock. We apologize for the inconvenience. Please check back later for availability.</h6>
                                            }

                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>

    <Footeroffline />

    </div>

    
    </>
    );
}

export default Preorderproduct;