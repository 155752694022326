import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { useNavigate} from 'react-router-dom';
import Search from './search';

ReactSession.setStoreType("localStorage");


function App() {
    const userId = ReactSession.get("arkuserId");
    const navigate = useNavigate();
    const [isSidebarVisible, setSidebarVisible] = useState(false);
    const [searchProductData, setSearchProductData] = useState(null);
    const [isSticky, setIsSticky] = useState(false);
    const [searchName, setSearchNameValue] = useState('');
    const [searchId, setSearchIdValue] = useState('');
    const [searchNameMob, setSearchNameMobValue] = useState('');
    const [searchIdMob, setSearchIdMobValue] = useState('');
    const texts = ["Arivomkadaii", "அறிவோம்கடைi"];
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [cartItems, setCartItems] = useState([]);
    const [scroll, setScroll] = useState("");
    const [total, setTotal] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [searchProductDatas, setSearchProductDatas] = useState([]);
    const [preorder, setPreorder] = useState([]);
    const [preordertotal, setpreorderTotal] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState({ productId: '', quantity: 0, stock:0, preorder:'', code:'', stockpreorder:''});
    //console.log(selectedItem);
  

    const carts =() =>{
      const storedCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
      setCartItems(storedCartItems);
      updateCart();
    };

    const preorders =() =>{
      const storedPreorderItems = JSON.parse(sessionStorage.getItem('preorder')) || [];
      setPreorder(storedPreorderItems);
      updatedpreCart();
    };
    
    

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
      };
    
    const searchProducts = async (inputData) => {
      if (inputData.trim() !== '') {
        try {
          let response;
          if (!isNaN(inputData)) {
              response = await axios.post('https://api.arivomkadai.com/offlinesearch/', { product: inputData, from: 'product_code' });
            } else {
              response = await axios.post('https://api.arivomkadai.com/offlinesearch/', { product: inputData, from: 'product_name' });
            }
              setSearchProductDatas(response.data.data || []); 
            } catch (error) {
                console.error("Error fetching data:", error);
            }
      }else{
        setSearchProductDatas([]);
        setSearchInput('');
      }
    };

    const handleChange = (event) => {
        setSearchInput(event.target.value);
        const inputsValue = event.target.value;
        searchProducts(inputsValue);
    };

  
    useEffect(() => {
      const textToType = texts[currentIndex % texts.length];
      if (currentText !== textToType) {
        const timeout = setTimeout(() => {
          setCurrentText(prevText => {
            const nextChar = textToType[prevText.length];
            return prevText + nextChar;
            
          });
          carts();
          preorders();
        }, 200);
  
        return () => clearTimeout(timeout);
      } else {
        setCurrentIndex(prevIndex => prevIndex + 1);
        setCurrentText('');
      }
    }, [currentText, currentIndex, 100, texts]);


    const toggleSidebar = () => {
      setSidebarVisible(!isSidebarVisible); 
    };
    
    const openModal = () => {
        const modal = document.getElementById('onloadModal');
        if (modal) {
            modal.classList.add('show');
            modal.style.display = 'block';
            document.body.classList.add('modal-open');
        }
    };

    const closeModal = () => {
        const modal = document.getElementById('onloadModal');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            document.body.classList.remove('modal-open');
        }
    };
    


    useEffect(() => {
        const handleScroll = () => {
            const scroll = window.scrollY;
            if (scroll < 200) {
                setIsSticky(false);
            } else {
                setIsSticky(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const searchByName = (e) => {
        setSearchNameValue(e.target.value);
        setSearchIdValue('');
        setSearchNameMobValue('');
        setSearchIdMobValue('');

    };

    const searchById = (e) => {
        setSearchIdValue(e.target.value);
        setSearchNameMobValue('');
        setSearchIdMobValue('');
        setSearchNameValue('');
    };

    const searchByNameMob = (e) => {
        setSearchNameMobValue(e.target.value);
        setSearchIdValue('');
        setSearchIdMobValue('');
        setSearchNameValue('');
    };

    const searchByIdMob = (e) => {
        setSearchIdMobValue(e.target.value);
        setSearchIdValue('');
        setSearchNameMobValue('');
        setSearchNameValue('');
    };

    useEffect(() => {
        if (searchName.length > 0) {
            const modal = document.getElementById('searchNameModal');
            if (modal) {
                modal.classList.add('show');
                modal.style.display = 'block';
                document.body.classList.add('modal-open');
            }

            const fetchSearchData = async () => {
                try {
                    const response = await axios.post('https://api.arivomkadai.com/offlinesearch/', {product: searchName, from: 'product_name'});
                        setSearchProductData(response.data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };

            fetchSearchData();

        } else {
            const modal = document.getElementById('searchNameModal');
            if (modal) {
                modal.classList.remove('show');
                modal.style.display = 'none';
                document.body.classList.remove('modal-open');
            }
        }
      }, [searchName]);

      useEffect(() => {
        if (searchNameMob.length > 0) {

            const modal = document.getElementById('searchNameModalMob');
            if (modal) {
                modal.style.display = 'block';
            }

            const fetchSearchData = async () => {
                try {
                    const response = await axios.post('https://api.arivomkadai.com/offlinesearch/', {product: searchNameMob, from: 'product_name'});
                        setSearchProductData(response.data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };

            fetchSearchData();

        } else {
            const modal = document.getElementById('searchNameModalMob');
            if (modal) {
                modal.style.display = 'none';
            }
        }
      }, [searchNameMob]);



      useEffect(() => {
        if (searchId.length > 0) {

            const modal = document.getElementById('searchNameModal');
            if (modal) {
                modal.classList.add('show');
                modal.style.display = 'block';
                document.body.classList.add('modal-open');
            }
            const fetchSearchData = async () => {
                try {
                    const response = await axios.post('https://api.arivomkadai.com/offlinesearch/', {product: searchId, from: 'product_code'});
                        setSearchProductData(response.data);
                } catch (error) {
      
                    console.error("Error fetching data:", error);
                }
            };

            fetchSearchData();

        } else {
            const modal = document.getElementById('searchNameModal');
            if (modal) {
                modal.classList.remove('show');
                modal.style.display = 'none';
                document.body.classList.remove('modal-open');
            }
        }
      }, [searchId]);

      useEffect(() => {
        if (searchIdMob.length > 0) {

            const modal = document.getElementById('searchNameModalMobId');
            if (modal) {
                modal.style.display = 'block';
            }

            const fetchSearchData = async () => {
                try {
                    const response = await axios.post('https://api.arivomkadai.com/offlinesearch/', {product: searchIdMob, from: 'product_code'});
                        setSearchProductData(response.data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };

            fetchSearchData();
        } else {
            const modal = document.getElementById('searchNameModalMobId');
            if (modal) {
                modal.style.display = 'none';
            }
        }
      }, [searchIdMob]);


      const closeSearchNameModal = () => {
        setSearchIdMobValue('');
        setSearchIdValue('');
        setSearchNameMobValue('');
        setSearchNameValue('');
        const modal = document.getElementById('searchNameModal');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            document.body.classList.remove('modal-open');
        }
      };
      

    const handleLogout = () => {
        ReactSession.set("arkuserId", "0");
        ReactSession.set("userName", "");
        ReactSession.set("email", "");
        ReactSession.set("profile", "");
        ReactSession.set("phonenumber", "");
        ReactSession.set("gender", "");
        ReactSession.set("building", "");
        ReactSession.set("street", "");
        ReactSession.set("city", "");
        ReactSession.set("state", "");
        ReactSession.set("pincode", "");
        sessionStorage.removeItem('cartItems');
        sessionStorage.removeItem('preorder');
        sessionStorage.removeItem('offer');
        sessionStorage.removeItem('preorderoffer');
        sessionStorage.removeItem('deliveryAmount');
     navigate('/login'); 
    }

const openModal1 = (stock) => {
  const modal = document.getElementById('copyModal11');
  const count = document.getElementById('stockCount');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
      count.textContent = stock;
     
  }
};

    
    
  
  
  
        const closeModal1 = () => {
          const modal = document.getElementById('copyModal11');
          if (modal) {
              modal.classList.remove('show');
              modal.style.display = 'none';
              document.body.classList.remove('modal-open');
          }
        };

        
        
        
        const contPreOrders = () => {
            // Destructure productId and quantity from selectedItem
            const { productId, quantity, stock, preorder, code, stockpreorder} = selectedItem;
            // Call updateCart with productId and quantity
           // console.log(`Updating cart for productId: ${productId}, quantity: ${quantity}, stock: ${stock}, preorder: ${preorder}`);
   
    
            const carts = JSON.parse(sessionStorage.getItem('cartItems')) || [];
            const productIndex = carts.findIndex(item => item.id === productId);
        carts[productIndex].preorder = 'yes';
        sessionStorage.setItem('cartItems', JSON.stringify(carts)); // Save updated cart items to session storage
        setCartItems(carts);
        updateCart(productId, quantity, stock, preorder, code, stockpreorder);
        closeModal1();
          };
        
       
          const openmodalpreorders = (stock) => {
            const modal = document.getElementById('copyModalspreorders');
            if (modal) {
                modal.classList.add('show');
                modal.style.display = 'block';
                document.body.classList.add('modal-open');
                
            }
            
          
            
          };
          const closeModalspreorders = () => {
            const modal = document.getElementById('copyModalspreorders');
            if (modal) {
              modal.classList.remove('show');
              modal.style.display = 'none';
              document.body.classList.remove('modal-open');
              
              
              const inputField = document.querySelector('.qty-val');
              if (inputField) {
                inputField.value = ''; 
              }
            }
          };



    const handleQuantityChange = async (itemId, event) => {
        const value = event.target.value;
        const quantity = parseInt(value);
    
        try {
            const response = await axios.get(`https://api.arivomkadai.com/offlineproduct/`);
            const product = response && response.data && response.data.data.filter(items => items.p_id === itemId);
            const stock = product[0].stock;
            const code = product[0].product_code;
            const stockpreorder = product[0].preorder;
            if (!isNaN(quantity)) {
                // Update quantity state with the parsed integer value
                updateCart(itemId, quantity, stock, stockpreorder, code)// Assuming updateCart function updates the cart state with itemId and new quantity
            } else {
                // Handle cases where the input value is not a valid number
                updateCart(itemId, '');
                // You might want to handle this case by resetting the input value or displaying an error message
            }
        } catch (error) {
            console.error("Error updating quantity:", error);
        }
    };
    
    
    


    const handleIncrement = async (productId, quantity) => {
        try {
            const response = await axios.get(`https://api.arivomkadai.com/offlineproduct/`);
            const product = response && response.data && response.data.data.filter(items => items.p_id === productId);
            const stock = product[0].stock;
            const code = product[0].product_code;
            const stockpreorder = product[0].preorder;
            const productname = product[0].product;
            updateCart(productId, quantity + 1, stock, code, stockpreorder); 
           // console.log(`Updated cart for ${productname}: ${quantity + 1}`);
        } catch (error) {
            console.error("Error fetching stock data:", error);
        }
    };
    
    const handleDecrement = async (productId, quantity) => {
        try {
            const response = await axios.get(`https://api.arivomkadai.com/offlineproduct/`);
            const product = response && response.data && response.data.data.filter(items => items.p_id === productId);
            const stock = product[0].stock;
            const code = product[0].product_code;
            const stockpreorder = product[0].preorder;
            const productname = product[0].product;
    if (quantity > 1) {
               // console.log(quantity);
                updateCart(productId, quantity - 1, stock, code, stockpreorder);
               // console.log(`Updated cart for ${productname}: ${quantity - 1}`);
            } else {
               // alert("Quantity cannot be less than 1");
            }
        } catch (error) {
            console.error("Error fetching stock data:", error);
        }
    };
    
      
      
      


      

      

      const removeProducts = (index) => {
        const updatedCart = [...cartItems];
        updatedCart.splice(index, 1);
        setCartItems(updatedCart);
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCart));
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
      };

      useEffect(() => {
        if (cartItems && cartItems.length > 0) {
          let sum = 0;
          cartItems.forEach(item => {
            sum += item.price * item.quantity; // Assuming each item has price and quantity properties
          });
          setTotal(sum);
        }
       
      }, [cartItems]); 


      const updateCart = (productId, quantity, stock, code, stockpreorder) => {
        const carts = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        const productIndex = carts.findIndex(item => item.id === productId);
        
        if (productIndex === -1) {
            return; // Product not found in cart, do nothing
        }
        
        if (stock >= quantity) {
            carts[productIndex].quantity = quantity; 
            carts[productIndex].preorder = 'No';// Update quantity in cart if stock is sufficient
        } else {
            if (carts[productIndex].preorder === 'yes') {
                carts[productIndex].quantity = quantity; // Allow preorder if specified
            } else {
                if(stockpreorder === '0'){
                const newItem = {
                    code: code,
                    productId: productId,
                    quantity: quantity,
                    stock: stock,
                    stockpreorder:stockpreorder,
                    preorder:'yes'

                };
                setSelectedItem(newItem);
               // console.log(newItem);
                openModal1(stock);
            }else{
                openmodalpreorders(stock);
          }
              
    
                // User confirmed to add as preorder
                
            
        }
        }
       
        
        sessionStorage.setItem('cartItems', JSON.stringify(carts)); // Save updated cart items to session storage
        setCartItems(carts); // Update the UI with the modified cart items
    };
    

   
    
    
    useEffect(() => {
       
        updateCart();
    }, []); 
    
   


    useEffect(() => {
        const fetchScrollData = async () => {
          try {
           
            const response = await axios.post(`https://api.arivomkadai.com//scrolloffline/`);
            const scroll = response.data.data[0].content;
            setScroll(scroll); 
          } catch (error) {
            console.error('Error fetching old data:', error);
          }
        };
        fetchScrollData();
      }, []);
    
   
     //preorder
     
     const handlepreorderIncrement = (productId, quantity) => {
        if (quantity < 10) {
            updatedpreCart(productId, quantity + 1); 
        }
        else{
            updatedpreCart(productId, 10); 
          }
      };
      
      const handlepreorderDecrement = (productId, quantity) => {
        if (quantity > 1) {
            updatedpreCart(productId, quantity - 1); 
        }
      };

      const productquantity=(productId, event)=>{
        const value=  parseInt(event.target.value);
        

        
        if(value >10){
            updatedpreCart(productId, 10); 
           
        }else{
            updatedpreCart(productId, value);
        }
    
     }
     

      
      


      const removepreProducts = (index) => {
        const updatedpreCart = [...preorder];
        updatedpreCart.splice(index, 1);
        setPreorder(updatedpreCart);
        sessionStorage.setItem('preorder', JSON.stringify(updatedpreCart));
        localStorage.setItem('preorder', JSON.stringify(updatedpreCart));
      };

      

      useEffect(() => {
        if (preorder && preorder.length > 0) {
          let sum = 0;
          preorder.forEach(item => {
            sum += item.price * item.quantity; 
          });
          setpreorderTotal(sum);
        } 
        else {
            setpreorderTotal(0); 
        
          }
        }, [preorder, preordertotal]); 
       


    




      const updatedpreCart = (productId, quantity) => {
        //console.log("Product:", productId);
        const preorder = JSON.parse(sessionStorage.getItem('preorder')) || [];
        //console.log("cart:", carts);
        const productIndex = preorder.findIndex(item => item.id === productId);
        //console.log("productIndex:", productIndex);
        if (productIndex === -1) {
            //console.log('Product not found in cart.');
            return;
        }
        if(quantity>0){
            preorder[productIndex].quantity = quantity;
           }else{
             preorder[productIndex].quantity = '';
           }
        
        
        //preorder[productIndex].quantity = quantity;
        sessionStorage.setItem('preorder', JSON.stringify(preorder)); // Change 'cart' to 'cartItems'
        setPreorder(preorder);
        //console.log('Updated cart:', carts);
        //console.log(cartItems);
    };
    
    useEffect(() => {
       
        updatedpreCart();
    }, []); 

    return (



<>
<div class="mobile-promotions">
<div class="scroll-left" >
  <div class="scrolling-container">
    <p>{scroll }</p>
   
  </div>
</div>
</div>
        <div class="header-top header-top-ptb-1 d-none d-lg-block">
            <div class="container">
                <div class="row align-items-center">
                 
                    <div class="col-xl-12 col-lg-4">
                 
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade custom-modal" id="copyModalspreorders" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
         <div class="modal-dialog" style={{maxWidth: '35%'}}>
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalspreorders}></button>
              <div class="modal-body bg-remover">
                <div class="login_wrap widget-taber-content background-white">
                  <div class="padding_eight_all bg-white" align="center">
                    <div class="heading_s1">
                      
                        <p class="mb-30">Insufficent Stock</p>
                        <button className="button mr-10 mt-10" onClick={closeModalspreorders}>Cancel</button>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade custom-modal" id="copyModal11" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
         <div class="modal-dialog" style={{maxWidth: '35%'}}>
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal1}></button>
              <div class="modal-body bg-remover">
                <div class="login_wrap widget-taber-content background-white">
                  <div class="padding_eight_all bg-white" align="center">
                    <div class="heading_s1">
                      <h4 class="mb-30">Out of Stock</h4>
                        {/* <p class="mb-30">Currently, this product is out of stock. Our executive will contact you within 24 hours after placing your order to discuss the delivery time.</p> */}
                        <p class="mb-30">Available stock is <span id="stockCount"></span>  If you wish, Kindly preorder the balance quantity after completing this order.</p>
                    
                    </div>
                    <button className="button mr-10 mt-10" onClick={contPreOrders}>Add to Cart</button>
                    <button className="button mr-10 mt-10" onClick={closeModal1}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        

<div class="modal fade custom-modal" id="onloadModal" tabindex="-1" aria-labelledby="onloadModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                <div class="modal-body">
                   <div class="login_wrap widget-taber-content background-white">
                                    <div class="padding_eight_all bg-white">
                                        <div class="heading_s1">
                                            <h1 class="mb-5">Contact Us</h1>
                                            <p class="mb-30">Let's talk about New Shopping Experience.</p>
                                        </div>
                                        <form method="post">
										  <div class="row">
										    <div class="col-lg-6 col-md-6">
                                              <div class="form-group">
                                                <input type="text" required="" name="name" placeholder="Enter your name..." />
                                              </div>
                                            </div>
										    <div class="col-lg-6 col-md-6">
                                              <div class="form-group">
                                                <input required="" type="text" name="number" placeholder="Enter your WhatsApp Number..." />
                                              </div>
                                            </div>
											<div class="col-lg-6 col-md-6">
											  <div class="form-group">
                                                <select required="" name="type">
												  <option selected hidden disabled>Select Purpose</option>
											      <option>Product or Service Promotion</option>
											      <option>Sell your products in our website</option>
											      <option>Product Enquiry for shopping</option>
                                                  <option>Others</option>
												</select>
                                              </div>
                                            </div>
											<div class="col-lg-6 col-md-6" align="center">
                                              <div class="form-group">
                                                <input required="" type="text" name="socialmedia" placeholder="Enter your Social Media Links..." />
                                              </div>
                                            </div>
                                          </div>
											
											<div class="form-group">
                                                <textarea rows="2" name="message" placeholder="Enter your Queries..." ></textarea>
                                            </div>
                                           
                                            <div class="form-group">
                                                <button type="submit" class="btn btn-heading btn-block hover-up" name="login">Submit Now</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade custom-modal" id="searchNameModal" tabindex="-1" aria-labelledby="searchNameModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeSearchNameModal}></button>
                <div class="modal-body">
                   <div class="login_wrap widget-taber-content background-white">
                                    <div class="padding_eight_all bg-white">
                                        
                                        {searchProductData && searchProductData.data && searchProductData.data.length > 0 ? (
                                            searchProductData.data.map(searchProduct => (
                                                <a className="product-link" href={`offlineproduct?id=${searchProduct.p_id}`}>
                                                  <img src={`https://api.arivomkadai.com/product_data/${searchProduct.image}`} alt={searchProduct.product} className="product-image" />
                                                  <div className="product-details">
                                                    <p className="product-name">{searchProduct.product}</p>
                                                    {/* <p className="product-price">₹ {searchProduct.price}</p> */}
                                                  </div>
                                                </a>

                                               ))
                                            ) : (
                                            <h5 align="center">No products found.</h5>
                                        )}
                                    </div>
                                </div>
                </div>
            </div>
        </div>
    </div>



      <header class="header-area header-style-1 header-height-2">
        <div class="header-middle header-middle-ptb-1 d-none d-lg-block" style={{background:'#fff'}}>
            <div class="container">
                <div class="header-wrap">
                    <div class="logo logo-width-1 col-3">
                            <h4>
                              <a href="/" class="typewrite" data-period="2000" data-type={currentText}>
                              <span class="wrap">{currentText}</span>
                              </a>
                            </h4>
                    </div>
                    <div class="header-right">
                        <div class="search-style-1">
                            <form action='javascript:void(0);' style={{zIndex: '3'}}>
                                <input type="text" value={searchName} onChange={searchByName} placeholder="Search for items by name..." />
                            </form>
                        </div>
                        <div class="search-style-1">
                            <form action='javascript:void(0);' style={{zIndex: '3'}}>
                                <input type="text" value={searchId} onChange={searchById} placeholder="Search for items by code..." />
                            </form>
                        </div>
                        <ul style={{display:'flex'}}>
                            <li style={{padding: '5px'}}><a href="/offline">Home</a></li>
                            <li style={{padding: '5px'}}><a href="/about">About</a></li>
                            <li style={{padding: '5px'}}><a href="/contact">contact</a></li>
                        </ul>
                        
                        <div class="header-action-right">
                            <div class="header-action-2">
                                
                                <div class="header-action-icon-2">
                                  <a id="openModalButton" onClick={openModal}><img src="assets/imgs/theme/icons/icon-headphone.svg"             alt="hotline"  /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-bottom header-bottom-bg-color sticky-bar" className={`sticky-bar ${isSticky ? 'stick' : ''}`} style={{paddingTop:'40px', paddingBottom:'10px'}}>
            <div class="container" >
                <div class="header-wrap header-space-between position-relative">
                    <div class="header-action-icon-2 d-block d-lg-none">
                        <div class="burger-icon burger-icon-white" onClick={toggleSidebar}>
                            <span class="burger-icon-top"></span>
                            <span class="burger-icon-mid"></span>
                            <span class="burger-icon-bottom"></span>
                        </div>
                    </div>
                    <div class="logo logo-width-1 d-block d-lg-none">
                             <h4>
                              <a href="/" class="typewrite" data-period="2000" data-type={currentText}>
                              <span class="wrap">{currentText}</span>
                              </a>
                            </h4>
                    </div>
                    <div class="header-nav d-none d-lg-flex">
                        <div class="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                            <nav>
                                <ul>
                                    
                                    <li><a href='/offlinecategory?id=Tech'>Tech</a></li>
                                    <li><a href='/offlinecategory?id=Home'>Home Decor</a></li>
                                    <li><a href='/offlinecategory?id=Kitchen'>Kitchen</a></li>
                                    <li><a href='/offlinecategory?id=Kids'>Kids</a></li>
                                    <li><a href='/offlinecategory?id=Gifts'>Gifts</a></li>
                                    <li><a href='/offlinecategory?id=Personalcare'>Personal Care</a></li>
                                    <li><a href='/offlinecategory?id=Health'>Health</a></li>
                                    <li><a href='/offlinecategory?id=Automobile'>Automobile</a></li>
                                    <li><a href='/offlinecategory?id=Fashions'>Fashions</a></li>
									<li><a href='/offlinecategory?id=Used'>Used Products</a></li>
                                    <li><a href='/live-shopping'>Live Shopping</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <ul>
                        <li> 
                          

<div className="header-action-right">
            <div className="header-action-2">
            {cartItems.length > 0 ?(<>
                <div className="header-action-icon-2">
                    <a className='mini-cart-icon' onClick={toggleVisibility}> 
                        {isVisible ? '' : ''}
                        <img alt="Nest" src="assets/imgs/theme/icons/icon-cart.svg" />
                        <span className="pro-count blue">{cartItems.length}</span>
                    </a>
                    

                

                    
                    { (
                        <div className={isVisible ? 'cart-dropdown-wrap cart-dropdown-hm2' : 'hidden'}>
                        {/* <div className="cart-dropdown-wrap cart-dropdown-hm2">  */}
                            {cartItems.length === 0 ? (
                                <div className="row">
                                    <div className="col-lg-8 mb-40">
                                        <h5 className="heading-2 mb-10">Your Cart</h5>
                                        <h6 className="text-body"> Please add items to your cart.</h6>
                                        <div className="d-flex justify-content-between mt-5">
                                            <div className="cart-action d-flex justify-content-between">
                                                <a href="/offline" className="btn"><i className="fi-rs-arrow-left mr-10"></i>Shop Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <ul style={{ listStyleType: "none", padding: 0 }}>
                                    {cartItems.map((item, index) => (
                                        <li key={index} style={{ marginBottom: "20px", borderBottom: "1px solid #ccc", paddingBottom: "10px" }}>
                                            <div className="shopping-cart-img">
                                                <a href="#"> {item.preorder === 'yes' ? (<div style={{ position: 'relative', display: 'inline-block' }}>
      <span style={{
        position: 'absolute',
        top: '0',
        right: '-30px',
        background: 'yellow',
        padding: '2px 15px',
        color: 'black',
        borderRadius: '15px',
        boxShadow: '3px 2px 2px rgba(0, 0, 0, 0.2)',
        zIndex: 1
      }}>
        Pre Order
      </span>
      <img alt={item.product} src={`https://api.arivomkadai.com/product_data/${item.image}`} style={{marginTop: '10px', maxWidth: "80px" }} />
    </div>):(
        <img alt={item.product} src={`https://api.arivomkadai.com/product_data/${item.image}`} style={{ maxWidth: "60px", borderRadius: "10px" }} />
    )}</a>
                                            </div>
                                            <div className="shopping-cart-titles">
                                                <button className="btn2" onClick={() => handleDecrement(item.id, item.quantity)}>-</button>
                                                <div className="detail-qtys border radius" style={{ margin: "0 10px" }}>
                                                    <input
                                                        type="number"
                                                        name="quantity"
                                                        className="qty-vals"
                                                        placeholder={item.quantity}
                                                        min="1"
                                                        // onChange={(e) => {
                                                        //     updateCart(parseInt(e.target.value));
                                                        // }}
                                                        onChange={(e) => handleQuantityChange(item.id, e)} // Pass item id and event object
      
                                                    />
                                                </div>
                                                <button className="btn2" onClick={() => handleIncrement(item.id, item.quantity)}>+</button>
                                            </div>
                                            <div className="shopping-cart-title" style={{ flexGrow: 1, }}>
                                                <p>{item.product.length > 50 ? `${item.product.slice(0, 50)}...` : item.product}</p>
                                                <small>₹ {Number(item.price).toFixed(2)} x {item.quantity}</small>
                                                {/* <h4>₹{item.price}</h4> */}
                                            </div>
                                          
                                            <div className="shopping-cart-delete">
                                                <a onClick={() => removeProducts(index)}><i className="fi-rs-cross-small"></i></a>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                            <div className="shopping-cart-footer">
                                <div className="shopping-cart-total">
                                    <h4>Total <span>₹{total}.00</span></h4>
                                </div>
                                <div className="shopping-cart-button"  align="center">
                                    <a className='btn btn-fill-out btn-block hover-up font-weight-bold w-100' href='/addcart'>View Cart</a>
                                    {/* <a href='/checkout'>Checkout</a> */}
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                    )}
                </div></>):(<>
                    <div className="header-action-icon-2">
                    <a className='mini-cart-icon' onClick={toggleVisibility}> 
                        {isVisible ? '' : ''}
                        <img alt="Nest" src="assets/imgs/theme/icons/icon-cart.svg" />
                        <span className="pro-count blue">{preorder.length}</span>
                    </a>
                    

                

                    
                    { (
                        <div className={isVisible ? 'cart-dropdown-wrap cart-dropdown-hm2' : 'hidden'}>
                        {/* <div className="cart-dropdown-wrap cart-dropdown-hm2">  */}
                            {preorder.length === 0 ? (
                                <div className="row">
                                    <div className="col-lg-8 mb-40">
                                        <h5 className="heading-2 mb-10">Your Cart</h5>
                                        <h6 className="text-body"> Please add items to your cart.</h6>
                                        <div className="d-flex justify-content-between mt-5">
                                            <div className="cart-action d-flex justify-content-between">
                                            <a href="/offline" class="btn "><i class="fi-rs-arrow-left mr-10"></i>Shop Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <ul style={{ listStyleType: "none", padding: 0 }}>
                                    {preorder.map((item, index) => (
                                        <li key={index} style={{ marginBottom: "20px", borderBottom: "1px solid #ccc", paddingBottom: "10px" }}>
                                            <div className="shopping-cart-img">
                                                <a href="#"><img alt="Nest" src={`https://api.arivomkadai.com/product_data/${item.image}`} style={{  maxWidth: "60px", borderRadius: "10px" }} /></a>
                                            </div>
                                            <div className="shopping-cart-titles">
                                                <button className="btn2" onClick={() => handlepreorderDecrement(item.id, item.quantity)}>-</button>
                                                <div className="detail-qtys border radius" style={{ margin: "0 10px" }}>
                                                    <input
                                                        type="number"
                                                        name="quantity"
                                                        className="qty-vals"
                                                        value={item.quantity}
                                                        min="1"
                                                        onChange={(e) => productquantity(item.id, e)}
                                                        // onChange={(e) => {
                                                          
                                                        //     updatedpreCart(parseInt(e.target.value));
                                                        // }}
                                                    />
                                                </div>
                                                <button className="btn2" onClick={() => handlepreorderIncrement(item.id, item.quantity)}>+</button>
                                            </div>
                                            <div className="shopping-cart-title" style={{ flexGrow: 1, }}>
                                                <p>{item.product.length > 50 ? `${item.product.slice(0, 50)}...` : item.product}</p>
                                                <small>₹ {Number(item.price).toFixed(2)} x {item.quantity}</small>
                                                {/* <h4>₹{item.price}</h4> */}
                                            </div>
                                            <div className="shopping-cart-delete">
                                                <a onClick={() => removepreProducts(index)}><i className="fi-rs-cross-small"></i></a>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                            <div className="shopping-cart-footer">
                                <div className="shopping-cart-total">
                                    <h4>Total <span>₹{preordertotal}.00</span></h4>
                                </div>
                                <div className="shopping-cart-button"  align="center">
                                    <a className='btn btn-fill-out btn-block hover-up font-weight-bold w-100' href='/addcart'>View Cart</a>
                                    {/* <a href='/checkout'>Checkout</a> */}
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                    )}
                </div>
            </>)}
               

                <div className="header-action-icon-2">
                    <a >
                        <img className="svgInject" alt="Nest" src="assets/imgs/theme/icons/icon-user.svg" />
                    </a>
                    
                    <a href="#"><span className="lable ml-0"></span></a>
                    
                    {  (
                        <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                            {userId === '0' ? (
                                <ul>
                                    <li className='mv-34'>
                                        <a href="/login"><i className="fi fi-rs-sign-out mr-10"></i>Sign in</a>
                                    </li>
                                </ul>
                            ) : (
                                <ul>
                                    <li className='mv-34'>
                                        <a href='/account'><i className="fi fi-rs-user mr-10 mv-34"></i>My Account</a>
                                    </li>
                                    <li className='mv-34'>
                                        <a href='/wishlist'><i className="fi fi-rs-heart mr-10"></i>My Wishlist</a>
                                    </li>
                                    <li className='mv-34'>
                                        <a onClick={handleLogout}><i className="fi fi-rs-sign-out mr-10"></i>Sign out</a>
                                    </li>
                                </ul>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>




                        </li>
                    </ul>
                    <div class="header-action-right d-block d-lg-none" style={{marginLeft:'10px'}}>
                        <div class="header-action-2">
                           {cartItems.length > 0   ?(<>
                           
                            <div class="header-action-icon-2">
                            <a id="openModalButton" onClick={openModal}><img src="assets/imgs/theme/icons/icon-headphone.svg"             alt="hotline"  /></a>
                            </div>
                            <div class="header-action-icon-2">
                            <a className='mini-cart-icon' onClick={toggleVisibility}> 
                            {isVisible ? '' : ''}
                            <img alt="Nest" src="assets/imgs/theme/icons/icon-cart.svg" />
                            <span className="pro-count blue">{cartItems.length}</span>
                            </a>
                            <div className={isVisible ? 'cart-dropdown-wrap cart-dropdown-hm2' : 'hidden'}>

{cartItems.length === 0 ? (
      <div class="row">
      <div class="col-lg-8 mb-40">
          <h5 class="heading-2 mb-10">Your Cart</h5>
          <h6 class="text-body"> Please add items to your cart.</h6>
          <div class="d-flex justify-content-between mt-5">
          <div class="cart-action d-flex justify-content-between" >
              <a href="/offline" class="btn "><i class="fi-rs-arrow-left mr-10"></i>Shop Now</a>
          </div>
                </div>
      </div>
  </div>
):(<ul>
    {cartItems.map((item, index) => (
    <li key={index} style={{ marginBottom: "20px", borderBottom: "1px solid #ccc", paddingBottom: "10px" }}>
        <div className="shopping-cart-img">
          <a href='#'> {item.preorder === 'yes' ? (<div style={{ position: 'relative', display: 'inline-block' }}>
      <span style={{
        position: 'absolute',
        top: '0',
        right: '-30px',
        background: 'yellow',
        padding: '2px 15px',
        color: 'black',
        borderRadius: '15px',
        boxShadow: '3px 2px 2px rgba(0, 0, 0, 0.2)',
        zIndex: 1
      }}>
        Pre Order
      </span>
      <img alt={item.product} src={`https://api.arivomkadai.com/product_data/${item.image}`} style={{marginTop: '10px', maxWidth: "60px" }} />
    </div>):(
        <img alt={item.product} src={`https://api.arivomkadai.com/product_data/${item.image}`} style={{  maxWidth: "60px", borderRadius: "10px" }} />
    )}</a>
        </div>
        <div className="shopping-cart-titles">
          <button className="btn2" onClick={() => handleDecrement(item.id, item.quantity)}>-</button>
          <div className="detail-qtys border radius" style={{ margin: "0 10px" }}>
            <input
              type="number"
              name="quantity"
              className="qty-vals"
              placeholder={item.quantity}
              min="1"

              onChange={(e) => handleQuantityChange(item.id, e)} // Pass item id and event object
      
          
            />
          </div>
          <button className="btn2" onClick={() => handleIncrement(item.id, item.quantity)}>+</button>
        </div>
        <div className="shopping-cart-title" style={{ flexGrow: 1, }}>
            <p>{item.product.length > 50 ? `${item.product.slice(0, 50)}...` : item.product}</p>
            <small>₹ {Number(item.price).toFixed(2)} x {item.quantity}</small>
        </div>
        <div className="shopping-cart-delete">
          <a onClick={() => removeProducts(index)}><i className="fi-rs-cross-small"></i></a>
        </div>
    </li>
     ))}
    
    </ul>)}
    
    <div class="shopping-cart-footer">
        <div class="shopping-cart-total">
            <h4>Total <span>₹{total}.00</span></h4>
        </div>
        <div className="shopping-cart-button"  align="center">
            <a className='btn btn-fill-out btn-block hover-up font-weight-bold w-100' href='/addcart'>View Cart</a>
            {/* <a href='/checkout'>Checkout</a> */}
        </div>
    </div>
</div>
                            </div>
                           
                           </>)
                           :
                           (<>
                            <div class="header-action-icon-2">
                            <a id="openModalButton" onClick={openModal}><img src="assets/imgs/theme/icons/icon-headphone.svg"             alt="hotline"  /></a>
                            </div>
                            <div class="header-action-icon-2">
                            <a className='mini-cart-icon' onClick={toggleVisibility}> 
                            {isVisible ? '' : ''}
                            <img alt="Nest" src="assets/imgs/theme/icons/icon-cart.svg" />
                            <span className="pro-count blue">{preorder.length}</span>
                            </a>
                            <div className={isVisible ? 'cart-dropdown-wrap cart-dropdown-hm2' : 'hidden'}>

{preorder.length === 0 ? (
      <div class="row">
      <div class="col-lg-8 mb-40">
          <h5 class="heading-2 mb-10">Your preorder Cart</h5>
          <h6 class="text-body"> Please add items to your cart.</h6>
          <div class="d-flex justify-content-between mt-5">
          <div class="cart-action d-flex justify-content-between" >
              <a href="/live-shopping" class="btn "><i class="fi-rs-arrow-left mr-10"></i>Shop Now</a>
          </div>
                </div>
      </div>
  </div>
):(<ul>
    {preorder.map((item, index) => (
    <li key={index} style={{ marginBottom: "20px", borderBottom: "1px solid #ccc", paddingBottom: "10px" }}>
        <div className="shopping-cart-img">
          <a href='#'><img alt="Nest" src={`https://api.arivomkadai.com/product_data/${item.image}`} style={{ maxWidth: "850px" }} /></a>
        </div>
        <div className="shopping-cart-titles">
          <button className="btn2" onClick={() => handlepreorderDecrement(item.id, item.quantity)}>-</button>
          <div className="detail-qtys border radius" style={{ margin: "0 10px" }}>
            <input
              type="text"
              name="quantity"
              className="qty-vals"
              value={item.quantity}
              min="1"
              onChange={(e) => productquantity(item.id, e)}
            />
          </div>
          <button className="btn2" onClick={() => handlepreorderIncrement(item.id, item.quantity)}>+</button>
        </div>
        <div className="shopping-cart-title pl-5" style={{ flexGrow: 1 }}>
          <h4>{item.product.length > 50 ? `${item.product.slice(0, 50)}...` : item.product}</h4> 
           {/* <h4>₹{item.price}</h4>  */}
           </div>
           <div className="shopping-cart-title pl-40" style={{ flexGrow: 1 }}>
          <h4>₹{item.price*item.quantity}</h4>
        </div>
        <div className="shopping-cart-delete">
          <a onClick={() => removepreProducts(index)}><i className="fi-rs-cross-small"></i></a>
        </div>
    </li>
     ))}
    
    </ul>)}
    
    <div class="shopping-cart-footer">
        <div class="shopping-cart-total">
            <h4>Total <span>₹{preordertotal}.00</span></h4>
        </div>
        <div class="shopping-cart-button">
            <a class='outline' href='/addcart'>View cart</a>
            {/* <a href='/checkout?id=preorder'>Checkout</a> */}
        </div>
    </div>
</div>
                            </div>
                           </>)}
                           

                            <div class="header-action-icon-2">
                                    <a>
                                        <img class="svgInject" alt="Nest" src="assets/imgs/theme/icons/icon-search.png" />
                                    </a>
                                    
                                   
                                    <div class="cart-dropdown-wrap cart-dropdown-hm2 account-dropdowns">
                                    <input type="text" value={searchInput} onChange={handleChange} placeholder="Search for items by code or name..." />
            <div className="extraDiv" >
                {searchInput !== '' && (
                searchProductDatas.length > 0 ? (
                    <ul>
                        {searchProductDatas.map(searchProduct => (
                            <li key={searchProduct.p_id}>
                                <a href={`offlineproduct?id=${searchProduct.p_id}`}>
                                    <h6 className="p-20 fs-14">{searchProduct.product}</h6>
                                </a>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No products found.</p>
                ))}
            </div>

                                    </div>
                                </div>




                            <div class="header-action-icon-2">
                                    <a href='#'>
                                        <img class="svgInject" alt="Nest" src="assets/imgs/theme/icons/icon-user.svg" />
                                    </a>
                                    <a href='#'><span class="lable ml-0"></span></a>
                                    <div class="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                                    {userId === '0' ? ( <ul>
                                                <li className='mv-34'>
                                                    <a href="/login"><i class="fi fi-rs-sign-out mr-10"></i>Sign in</a>
                                                </li>
                                            </ul>):(
                                                 <ul>
                                                 <li className='mv-34'>
                                                         <a href='/account'><i class="fi fi-rs-user mr-10 mv-34"></i>My Account</a>
                                                     </li>
                                                 
                                                     <li className='mv-34'>
                                                         <a href='/wishlist'><i class="fi fi-rs-heart mr-10 "></i>My Wishlist</a>
                                                     </li>
                                                 
                                                     <li className='mv-34'>
                                                         <a onClick={handleLogout}><i class="fi fi-rs-sign-out mr-10"></i>Sign out</a>
                                                     </li>
                                                 </ul>
                                            )}
                                    </div>
                                </div>



                              


                                


                               
                        </div>
                    </div>
                </div>

                <div class="mobile-promotions mobile-stick " style={{display:'none'}}>
                    <div class="scroll-left" >
                        <div class="scrolling-container">
                            <p>{scroll}</p>
                        </div>
                    </div>
                </div>
          
            </div>
        </div>
    </header>
    <div className={isSidebarVisible ? 'mobile-header-active mobile-header-wrapper-style sidebar-visible' : 'mobile-header-active mobile-header-wrapper-style'}>
        <div class="mobile-header-wrapper-inner">
            
            <div class="mobile-header-top">
                
                <div class="mobile-header-logo">
                            <h4>
                              <a href="/" class="typewrite" data-period="2000" data-type={currentText}>
                              <span class="wrap">{currentText}</span>
                              </a>
                            </h4>
                </div>
                <div class="mobile-menu-close close-style-wrap close-style-position-inherit">
                    <button class="close-style search-close" onClick={toggleSidebar}>
                        <i class="icon-top"></i>
                        <i class="icon-bottom"></i>
                    </button>
                </div>
            </div>
            <div class="mobile-header-content-area">
                <div class="mobile-search search-style-3 mobile-header-border">
                <form action='javascript:void(0);'>
                     <input type="text" value={searchNameMob} onChange={searchByNameMob} placeholder="Search for items by name..." />
                     <div class ="extraDiv" id="searchNameModalMob"> 
                        <ul>
                                        {searchProductData && searchProductData.data && searchProductData.data.length > 0 ? (
                                            searchProductData.data.map(searchProduct => (
                                                <li>
                                                <a  href={`offlineproduct?id=${searchProduct.p_id}`}><h6 class="p-20 fs-14">
                                                 {searchProduct.product} 
                                                 </h6></a>
                                               </li>
                                               ))
                                            ) : (
                                            <h5 class="p-20 fs-14" align="center">No products found.</h5>
                                        )}
                        </ul>
                    </div>
                </form>
                    
                </div>
                <div class="mobile-search search-style-3 mobile-header-border">
                    <form action='javascript:void(0);'>
                        <input type="number" value={searchIdMob} onChange={searchByIdMob} placeholder="Search for items by code..." />
                        <div class ="extraDiv" id="searchNameModalMobId"> 
                        <ul>
                                        {searchProductData && searchProductData.data && searchProductData.data.length > 0 ? (
                                            searchProductData.data.map(searchProduct => (
                                                <li>
                                                <a  href={`offlineproduct?id=${searchProduct.p_id}`}><h6 class="p-20 fs-14">
                                                 {searchProduct.product} 
                                                 </h6></a>
                                               </li>
                                               ))
                                            ) : (
                                            <h5 class="p-20 fs-14" align="center">No products found.</h5>
                                        )}
                        </ul>
                    </div>
                    </form>
                </div>
                <div class="mobile-menu-wrap mobile-header-border">
                  
                    <nav>
                        <ul class="mobile-menu font-heading">
                       
                            <li><a href='/offline'>Home</a></li>
                            <li><a href='/about'>About</a></li>
                            <li><a href='/contact'>Contact</a></li>
                            <li><a href='/offlinecategory?id=Tech'>Tech</a></li>
                                    <li><a href='/offlinecategory?id=Home'>Home Decor</a></li>
                                    <li><a href='/offlinecategory?id=Kitchen'>Kitchen</a></li>
                                    <li><a href='/offlinecategory?id=Kids'>Kids</a></li>
                                    <li><a href='/offlinecategory?id=Gifts'>Gifts</a></li>
                                    <li><a href='/offlinecategory?id=Personalcare'>Personal Care</a></li>
                                    <li><a href='/offlinecategory?id=Health'>Health</a></li>
                                    <li><a href='/offlinecategory?id=Automobile'>Automobile</a></li>
                                    <li><a href='/offlinecategory?id=Fashions'>Fashions</a></li>
									<li><a href='/offlinecategory?id=Used'>Used Products</a></li>
                                    <li><a href='/live-shopping'>Live Shopping</a></li>
                        </ul>
                    </nav>
                
                </div>
               
               
                <div class="site-copyright">© 2024  ArivomKadai All Rights Reserved. Websolutions By <a href="https://sninfoserv.com" target="_blank">SN Infoserv</a></div>
            </div>
        </div>
    </div>
</>

);
}

export default App;