import React, { useEffect, useState } from 'react';
import Header from './headerpages';
import Footer from './footeroffline';
import { ReactSession } from 'react-client-session';
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Footeroffline from './footeroffline';
import Razorpay from './razorpay';
import { CloseButton } from 'react-bootstrap';
ReactSession.setStoreType("localStorage");

 function Checkout() {
    
    const queryParameters = new URLSearchParams(window.location.search)
    const Preorder = queryParameters.get("id");
    const Offersession = sessionStorage.getItem('offer');
    const navigates = useNavigate();
    const userId = ReactSession.get("arkuserId");
    const otpStatus = ReactSession.get("otp");
    const [message, setMessage] = useState('');
    const [gst, setGst] = useState('');
 
    if(userId > 0 && otpStatus == 'no'){
       navigates('/test');
    }

    const userName = ReactSession.get("userName");
    const Email = ReactSession.get("email");
    const Phone = ReactSession.get("phonenumber");
    const Profile = ReactSession.get("profile");
    const [cartItems, setCartItems] = useState([]);
    const [preCartItems, setPreorder] = useState([]);
    const [total, setTotal] = useState(0);
    const [gtotal, setGTotal]= useState(0);
    const [shipping, setShipping] = useState(0);
    const [coupon, setCoupon] = useState(0);
    const [gift, setGift] = useState(0);
    const [packingCost, setPackingCost] = useState(0);
    const [giftvalue, setGiftValue] = useState(0);
    const [stprice, setStprice] = useState(0);
    const [selectedWeight, setSelectedWeight] = useState('');
    const [orderType, setOderType] = useState('regular');

    const [isbutton, setIsButton] = useState(true);
    const [isChecked, setIsChecked] = useState(false);

    const [razOrId, setRazId] = useState(null);
    const [OrId, setOrderID] = useState(null);
    const [ordrFrm, setOrderFrom] = useState(null);
    const [payClick, setpayClick] = useState(false);

    const [input, setInput] = useState({});
    const [error, setError]=useState({});
    const [loginError, setLoginError]=useState('');
    const [formsubmit, setsubmit]=useState(false);
    const navigate = useNavigate();
    const [address, setAddress] = useState({});
    const [addresses, setAddresses] = useState({});
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const [reg, setReg] = useState({});
   
    const [isVisible, setIsVisible] = useState(true);
    const [userids, setUserIds] = useState(userId);

    const [iframeUrl, setIframeUrl] = useState(null);
    

    //------Shipping-Provider---//

    const shippingProvider = async () => {
      const response = await axios.get('https://api.arivomkadai.com/courier/');
      if(response.data.status === 'success'){
        const provider = response.data.provider;
        if(provider === 'STCourier'){
          setIsButton(false);
        }else{
          setIsButton(true);
        }
      }
    }
    
    useEffect(() => {
      shippingProvider();
    },[isbutton]);



    
    const handleAddressSelection = (addressId) => {
      setSelectedAddressId(addressId); 
      
      const filteredAddresses = addresses && addresses.data && addresses.data.filter(item => item.id === addressId);
      const filteredState = filteredAddresses && filteredAddresses.length > 0 ? filteredAddresses[0].state : 'No matching address found.';
       
    
        switch (filteredState) {
            case 'Tamil Nadu':
              if (selectedWeight < 1) {
                setStprice(40);
                if (isbutton === false) {
                  const shipping = Number(packingCost) + 40;
                  setShipping(shipping);
                }
              } else if (selectedWeight >= 1 && selectedWeight < 2) {
                setStprice(50);
                if (isbutton === false) {
                  const shipping = Number(packingCost) + 50;
                  setShipping(shipping);
                }
              } else if (selectedWeight >= 2) {
                setStprice(80);
                if (isbutton === false) {
                  const shipping = Number(packingCost) + 80;
                  setShipping(shipping);
                }
              }
              break;
          
            case 'Kerala':
            case 'Karnataka':
            case 'Andhra Pradesh':
              if (selectedWeight < 1) {
                setStprice(50);
                if (isbutton === false) {
                  const shipping = Number(packingCost) + 50;
                  setShipping(shipping);
                }
              } else if (selectedWeight >= 1 && selectedWeight < 2) {
                setStprice(60);
                if (isbutton === false) {
                  const shipping = Number(packingCost) + 60;
                  setShipping(shipping);
                }
              } else if (selectedWeight >= 2) {
                setStprice(100);
                if (isbutton === false) {
                  const shipping = Number(packingCost) + 100;
                  setShipping(shipping);
                 
                }
              }
              break;
          
            case 'Other States':
              if (selectedWeight < 1) {
                setStprice(120);
                if (isbutton === false) {
                  const shipping = Number(packingCost) + 120;
                  setShipping(shipping);
                }
              } else if (selectedWeight >= 1 && selectedWeight < 2) {
                setStprice(140);
                if (isbutton === false) {
                  const shipping = Number(packingCost) + 140;
                  setShipping(shipping);
                }
              } else if (selectedWeight >= 2) {
                setStprice(160);
                if (isbutton === false) {
                  const shipping = Number(packingCost) + 160;
                  setShipping(shipping);
                }
              }
              break;
          
            default:
                setStprice(40);
                const shipping = Number(packingCost) + 40;
                setShipping(shipping);
            break;
          }
          
    };
    
    
    const fetchAddressData = async () => {
        const formData = {
          id: userId,
        };
    
        try {
          const response = await axios.post('https://api.arivomkadai.com/address/', formData);
          const fetchedAddresses = response.data;
          setAddresses(fetchedAddresses);
         // console.log(response.data);
          
         if (fetchedAddresses.data.length > 0) {
              const lastAddressId = fetchedAddresses.data[fetchedAddresses.data.length - 1].id;
              if(selectedAddressId === '' || selectedAddressId === null){
    
                  setSelectedAddressId(lastAddressId);
                  handleAddressSelection(lastAddressId);
              }
              
            
            }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
    };
    
    useEffect(() => {
      fetchAddressData();
      handleAddressSelection(selectedAddressId);
    }, [shipping, stprice, selectedAddressId]); 

   
    const handleCheckboxChange = () => {
      if(isChecked === false){
		    setGift(giftvalue);
		    setIsChecked(true);
	    }else{
		    setGift(0);
		    setIsChecked(false);
	    }
    };
	

    useEffect(() => {
        const fetchOldData = async () => {
          try {
            // Make API call to fetch old data
            const response = await axios.get(`https://api.arivomkadai.com/giftBox/`);
            const price = response.data.price;
            setGiftValue(price); 
          } catch (error) {
            console.error('Error fetching old data:', error);
          }
        };
    
        // Call the fetchOldData function
        fetchOldData();
    }, []);
	 

    //register
    const handleRegChange=(event)=>{
        const name = event.target.name;
        const value =event.target.value;
        setReg(values => ({...values, [name]: value}))
    }

    const handleregSubmit=async(event)=>{
        event.preventDefault();
    //console.log(reg);
    try {
        const response = await axios.post('https://api.arivomkadai.com/register/', reg);
        const result =response.data.status;
        if(result === 'success'){
            if(response.data.otp === 'no'){
                ReactSession.set("email", reg.email);
                ReactSession.set("otp", response.data.otp);
                navigate('/otp');
              }else{
                ReactSession.set("otp", response.data.otp);
                ReactSession.set("arkuserId", response.data.data[0].user_id);
                ReactSession.set("userName", response.data.data[0].name);
                ReactSession.set("email", response.data.data[0].email);
                ReactSession.set("phonenumber", response.data.data[0].phonenumber);
                ReactSession.set("gender", response.data.data[0].gender);
                ReactSession.set("profile", response.data.data[0].profile);
                ReactSession.set("building", response.data.data[0].buiulding);
                ReactSession.set("street", response.data.data[0].street);
                ReactSession.set("city", response.data.data[0].city);
                ReactSession.set("state", response.data.data[0].state);
                ReactSession.set("pincode", response.data.data[0].pincode);
                setUserIds(response.data.data[0].user_id);
                fetchAddressData();
                
             }
             setIsVisible(false);
            }else{
                const errors = response.data.error.message;
                setMessage(errors);
               
            }
        // console.log(message);
        // const userData = response.data;
        //console.log(userData);
        // navigate('/login');
        //alert(`Registered successfully!`);
    } catch (error) {
        // Handle error
        console.error('There was a problem with login:', error);
        setError('Invalid email or password');
    }
  };


  useEffect(() => {
    const storedCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];

    if(storedCartItems && storedCartItems.length === 0){
      const storedPreCartItems = JSON.parse(sessionStorage.getItem('preorder')) || [];
      setCartItems(storedPreCartItems);
      setOderType('liveShopping');
    }else{
      setCartItems(storedCartItems);
      setOderType('regular');
    }

    
  }, []);



 //-----Cart-calculations-----//

 useEffect(() => {
  
    let sum = 0;
    let weight = 0;
    let gst = 0;

    if (cartItems && cartItems.length > 0) {
    
      cartItems.forEach(item => {
        sum += item.price * item.quantity;
        gst += (item.price - (item.price/(1+item.gst/100)))*item.quantity;
        weight += item.shippingweight * item.quantity;
      });
    }

    
    setTotal(sum);
    setGst(gst);
    setSelectedWeight(weight);
    
    if(weight <= 1){
      setPackingCost(10);
    }else{
      setPackingCost(20);
    }

    if(sessionStorage.getItem('offer') != null){
        setCoupon(Number(Offersession).toFixed(2));
    }

    const GTotal = Number(total) + Number(shipping) + Number(gift) - Number(coupon);
	  setGTotal(GTotal);
    if( GTotal > 0){
        setOrderFrom('offline');
    }
  

}, [cartItems, packingCost, selectedWeight, shipping, gift, coupon]); 



  const handleAddChange=(event)=>{
    const name = event.target.name;
    const value =event.target.value;
    setAddress(values => ({...values, [name]: value}))
  };

  const handleAddSubmit =async(event)=>{
    
    event.preventDefault();
    const formData = {
        id: userids, // Include the userId in the form data
        ...address // Spread other address fields if they are separate
    };
    try {
        const response = await axios.post(`https://api.arivomkadai.com/addaddress/`, formData);
        fetchAddressData();
        handleCheckboxClick();
        window.reload();
    } catch (error) {
        console.error('There was a problem with login:', error);
        setError('Address Not Inserted');
    }
  };



const handleCheckboxClick = () => {
  const collapseAddress = document.getElementById('collapseAddress');
  const collapseAddress1 = document.getElementById('adrsbtn');
  const collapseAddress2 = document.getElementById('adrsbtn1');
  if (collapseAddress.classList.contains('show')) {
      collapseAddress.classList.remove('show');
      collapseAddress1.classList.remove('hide');
      collapseAddress2.classList.add('hide');
  } else {
      collapseAddress.classList.add('show');
      collapseAddress2.classList.remove('hide');
      collapseAddress1.classList.add('hide');
  }
};


if(!ReactSession.get("arkuserId")){
  ReactSession.set("arkuserId","0");
}else{
  ReactSession.set("arkuserId", ReactSession.get("arkuserId"));
};

const handleChange=(event)=>{
  const name = event.target.name;
  const value =event.target.value;
  setInput(values => ({...values, [name]: value}))
};

const handleSubmit =async(event)=>{
  event.preventDefault();
  setsubmit(true);
  
  try {
    const response = await axios.post('https://api.arivomkadai.com/logincustomer/', input);
    if(response.data.status === 'success' ){
        ReactSession.set("arkuserId", response.data.data[0].user_id);
        ReactSession.set("userName", response.data.data[0].name);
        ReactSession.set("email", response.data.data[0].email);
        ReactSession.set("phonenumber", response.data.data[0].phonenumber);
        ReactSession.set("gender", response.data.data[0].gender);
        ReactSession.set("profile", response.data.data[0].profile);
        ReactSession.set("building", response.data.data[0].buiulding);
        ReactSession.set("street", response.data.data[0].street);
        ReactSession.set("city", response.data.data[0].city);
        ReactSession.set("state", response.data.data[0].state);
        ReactSession.set("pincode", response.data.data[0].pincode);
        alert(`Welcome ${response.data.data[0].name}`);
        window.location.reload();
    }else{
        ReactSession.set("arkuserId", "0");
        const errors ='Username or Password is incorrect'
        setLoginError(errors);
        setError(errors);
    }
  } catch (error) {
        console.error('Login failed!', error);
        ReactSession.set("arkuserId", "0");
  }
};
    


//------------Payment Start-------------------//
      
      const processPayment = async(event) => {
       
          const dt = new Date();
          const time = userId + dt.getFullYear().toString() + (dt.getMonth() + 1).toString().padStart(2, '0') + dt.getDate().toString().padStart(2, '0') + dt.getHours().toString().padStart(2, '0') + dt.getMinutes().toString().padStart(2, '0') + dt.getSeconds().toString().padStart(2, '0');
          const orderId = time;

          setOrderID(time);
    
          const formData = {
              userId: userId,
              ids: selectedAddressId,
              total: total,
              g_total: gtotal,
              orderId:orderId,
              gift: gift,
			        offer: coupon,
			        shipping: shipping,
              orderType: orderType,
              products: cartItems.map(item => ({
                id: item.id,
                code: item.code,
                product: item.product,
                price: item.price,
                mrp: item.mrp,
                purchase: item.purchase,
                quantity: item.quantity,
                image: item.image,
                weight: item.weight,
                size: item.size,
                width: item.width,
                height: item.height,
                shippingweight: item.shippingweight,
                breadth: item.breadth,
                gst: item.gst ,
                vendor: item.vendor,
                vendorcommission: item.vendorcomission,
                hsn: item.hsn,
                preorder: item.preorder
              }))
          };
    
      try {
        const response = await axios.post(`https://api.arivomkadai.com/phonepe-payment/`, formData);
    
       
        if(response.data.status === 'success'){
            sessionStorage.removeItem('cartItems');
            sessionStorage.removeItem('offer');
            sessionStorage.removeItem('deliveryAmount');
            sessionStorage.removeItem('preorder');
            //setIframeUrl(response.data.RazId);
            window.location.href = response.data.RazId;
        }

    } catch (error) {
        console.error('There was a problem with login:', error);
        setError('Not Inserted');
    }
  }

  
  return (
    <>
    {iframeUrl && iframeUrl.length > 0 ? (
    <div align="center" style={{width: '100%',minHeight: '100vh', background: 'gray'}}>
            <div class="row" style={{minHeight: '95vh', padding: '10px'}} align="center">
                <div class="col-lg-4" align="center" style={{paddingTop: '40px', paddingBottom: '40px', marginLeft : 'auto', marginRight: 'auto', maxWidth: '450px'}}>
                <iframe
                    src={iframeUrl}
                    style={{ width: '100%', height: '100%', border: 'none', borderRadius: '20px' }}
                    title="Payment Success"
                />
                </div>
                <p class="text-center" style={{position: 'absolute', bottom: '30px', color: '#fff'}}>Do not refresh or press back button!</p>
            </div>
            
    </div>
   ):(
<>
    <div class="sidebars">
    <a  className="sidebars-on" href="/online-products">
      <h3 class="bar-content">Online Products </h3>
    </a>
</div>
<div class="main-bar">

  <Header  />

  <main class="main">


    <div class="page-header breadcrumb-wrap">
        <div class="container">
            <div class="breadcrumb">
                <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a>
                <span></span> Checkout
            </div>
        </div>
    </div>
    <div class="container mb-80 mt-50">
        <div class="row">
            <div class="col-lg-8 mb-40">
                <h1 class="heading-2 mb-10">Checkout</h1>
            </div>
        </div>

        { userId === '0' ? (
        <div class="row">
            <div class="col-lg-12">
                <div class="row mb-50">
                    <div class="col-lg-4 mb-sm-15 mb-lg-0 mb-md-3">
                        <div class="toggle_info">
                            <span><i class="fi-rs-user mr-10"></i><span class="text-muted font-lg">Already have an account?</span> <a href="#loginform" data-bs-toggle="collapse" class="collapsed font-lg" aria-expanded="false">Click here to login</a></span>
                        </div>
                        <div class="panel-collapse collapse login_form" id="loginform">
                            <div class="panel-body">
                                <p class="mb-30 font-sm">If you have shopped with us before, please enter your login details.</p>
                                <form onSubmit={handleSubmit}>
                                    <div class="form-group">
                                        <input type="email" required name="email" placeholder="Enter your Email ID..." value={input.email} onChange={handleChange}/>
                                    </div>
                                    <div class="form-group">
                                        <input required type="password" name="password" placeholder="Enter your Password" value={input.password} onChange={handleChange}/>
                                    </div>


                                    <div class="form-group">
                                        <button type="submit" class="btn btn-fill-out btn-block hover-up font-weight-bold">Log in</button>
                                        <a href="/login?id=forgot" class="pl-20">Forgot password?</a> {loginError && loginError.length > 0 && (
                                        <p className="text-danger">{loginError}</p>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        {isVisible && (
                        <form onSubmit={handleregSubmit}>
                            <div class="form-group">
                                <div class="toggle_info">
                                    <span><i class="fi-rs-user mr-10"></i><span class="text-muted font-lg">If you don't have an  account? create an account using checkbox</span> </span>
                                    <div class="checkbox">
                                        <div class="custome-checkbox">
                                            <input class="form-check-input" type="checkbox" name="checkbox" id="createaccount" />
                                            <label class="form-check-label label_info" data-bs-toggle="collapse" href="#collapsePassword" data-target="#collapsePassword" aria-controls="collapsePassword" for="createaccount"><span>Create an account?</span></label>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div id="collapsePassword" class="panel-collapse collapse login_form col-lg-6">
                                <div class="panel-body">
                                    <div class="row">
                                        <div class="form-group">
                                            <input type="text" required="" name="username" placeholder="Enter Full Name" value={reg.username} onChange={handleRegChange}/>
                                        </div>
                                        <div class="form-group">
                                            <input type="email" required="" name="email" placeholder="Enter Email" value={reg.email} onChange={handleRegChange}/>
                                        </div>
                                        <div class="form-group">
                                            <input required type="number" name="number" placeholder="Enter Phone Number" value={reg.number} onChange={handleRegChange}/>
                                        </div>
                                        <div class="form-group">
                                            <input required type="password" name="password" placeholder="Enter Password" value={reg.password} onChange={handleRegChange}/>
                                        </div>
                                        <div class="form-group">
                                            <input required type="text" name="building" placeholder="Enter Flat/Houseno/Building/company/Apartment/Area/streat/sector/village" value={reg.building} onChange={handleChange}/>
                                        </div>
                                        <div class="form-group">
                                            <input required type="text" name="street" placeholder="Enter Area/street/Sector/village" value={input.street} onChange={handleRegChange}/>
                                        </div>
                                        <div class="form-group">
                                            <input required type="text" name="city" placeholder="Enter Town/city" value={reg.city} onChange={handleRegChange}/>
                                        </div>
                                        <div class="form-group">
                                            <select required type="text" name="state" placeholder="Enter state" value={reg.state} onChange={handleRegChange}>
                                                <option value="" disabled selected hidden>Select State</option>
                                                <option value="Tamil Nadu">Tamil Nadu</option>
                                                <option value="Kerala">Kerala</option>
                                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                <option value="Karnataka">Karnataka</option>
                                                <option value="Other States">Other States</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <input required type="number" name="pincode" placeholder="Enter pincode" value={reg.pincode} onChange={handleRegChange}/>
                                        </div>



                                        <div class="form-group mb-30" align="center">
                                            <button type="submit" class="btn btn-fill-out btn-block hover-up font-weight-bold"> Register</button>

                                        </div>
                                        {message && message.length > 0 && (
                                        <p className="text-danger" align="center">{message}</p>
                                        )}

                                    </div>
                                </div>
                            </div>


                        </form>
                        )}
                    </div>
                </div>
            </div>
        </div> ):(

        <div class="row">
          {cartItems.length === 0 ? (
            
            <>
                <div class="row">
                    <div class="col-lg-12 mb-40" align="center">
                        <h5 class="heading-2 mb-10">Your Cart</h5>
                        <h6 class="text-body"> Please add items to your cart.</h6>
                       
                            <div class="cart-action mt-5" align="center">
                                <a href="/offline" class="btn "><i class="fi-rs-arrow-left mr-10"></i>Continue Shopping</a>
                            </div>
                      
                    </div>
                </div>
            </>
          ):(
            <>
                <div class="col-lg-7">

                        <div class="row mb-50">
                            <div class="col-lg-12 mb-sm-15 col-12 mb-lg-0 mb-md-3">
                                <div class="author-bio mba-blk p-30  border-radius-15 bg-white">

                                    <div class="author-image mb-5 col-lg-6 col-sm-6 col-12">
                                        <a href="#"><img src={`https://api.arivomkadai.com/customer_data/${Profile}`} height="100px" alt="" class="avatar" /></a>
                                        <div class="author-infor">
                                            <h5 class="mb-5">{userName}</h5>
                                            <p class="mb-0 text-muted font-xs">
                                                <span class="mr-10">{Email}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="mt-30 mb-5 col-lg-6 col-sm-6 col-12 btn-lft-align">
                                        {addresses && addresses.count
                                        < 4 && ( <>
                                            <button class="btn btn-danger mb-15" id="adrsbtn" onClick={handleCheckboxClick}>Add Address</button>
                                            <button class="btn btn-danger mb-15 hide" id="adrsbtn1" onClick={handleCheckboxClick}>X</button>
                                            </> )}
                                    </div>

                                </div>

                            </div>

                            <div class="col-lg-12 mb-sm-15 col-12 mb-lg-0 mb-md-3">

                                <form onSubmit={handleAddSubmit}>
                                    <div class="ship_detail mt-15">

                                        <div id="collapseAddress" class="different_address collapse in col-1g-12">
                                            <h4 class="mb-30"> Add Address</h4>
                                            <div class="row">
                                                <div class="form-group col-lg-6">
                                                    <input type="text" required name="name" placeholder="First name*" value={address.name} onChange={handleAddChange}/>
                                                </div>
                                                <div class="form-group col-lg-3">
                                                    <input type="text" required name="email" placeholder="Email*" value={address.email} onChange={handleAddChange}/>
                                                </div>
                                                <div class="form-group col-lg-3">
                                                    <input type="number" required name="number" placeholder="Whatsapp number*" value={address.number} onChange={handleAddChange}/>
                                                </div>

                                                <div class="form-group col-lg-6">
                                                    <input type="text" name="building" required placeholder="Enter Flat/House/Building/company/Apartment*" value={address.building} onChange={handleAddChange}/>
                                                </div>
                                                <div class="form-group col-lg-6">
                                                    <input type="text" name="street" required placeholder="Enter Area/street/Sector/village*" value={address.street} onChange={handleAddChange}/>
                                                </div>



                                                <div class="form-group col-lg-4">
                                                    <input required type="text" name="city" placeholder="Enter Town/city*" value={address.city} onChange={handleAddChange}/>
                                                </div>
                                                <div class="form-group col-lg-4">
                                                    <select required className="form-control" name="state" value={address.state} onChange={handleAddChange}>
                                                        <option value="" disabled selected hidden>Select State</option>
                                                        <option value="Tamil Nadu">Tamil Nadu</option>
                                                        <option value="Kerala">Kerala</option>
                                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                        <option value="Karnataka">Karnataka</option>
                                                        <option value="Other States">Other States</option>

                                                    </select>
                                                </div>

                                                {address.state === 'Other States' &&
                                                <div class="form-group col-lg-4">
                                                    <input required type="text" name="others" placeholder="Enter State*" value={address.others} onChange={handleAddChange}/>
                                                </div>
                                                }
                                                <div class="form-group col-lg-4">
                                                    <input required type="text" name="pincode" placeholder="Enter Pincode/ Zip *" value={address.pincode} onChange={handleAddChange}/>
                                                </div>
                                                <div class={`form-group ${address.state==='Other States' ? 'col-lg-8' : 'col-lg-12'}`}>
                                                    <input type="text" name="landmark" required placeholder="Enter Landmark*" value={address.landmark} onChange={handleAddChange}/>
                                                </div>

                                                <div class="form-group col-lg-12" align="center">
                                                    <button class="btn btn-success">Save Address</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>





                        <div class="row">
                            { addresses && addresses.count > 0 && (
                            <h4 class="mb-30">Select Address</h4>)} { addresses && addresses.data && addresses.data.slice().reverse().slice(-4).map(address => (

                            <div class="border p-15 cart-totals ml-30 mb-50 col-lg-5" key={address.id}>

                                <div class="custom-checkbox pr-5" style={{ display: 'flex', float: 'left' }}>

                                </div>
                                <div class='address-detail'>
                                    <p class='product-short-desc'><small>{address.name}</small></p>
                                    <p class='product-short-desc'><small>{address.building} {address.street}</small></p>
                                    <small class='content-color font-default'>Email : <span class='title-color font-default fw-500'>{address.email}</span></small>
                                    <br></br>
                                    <small class='content-color font-default'>Mobile : <span class='title-color font-default fw-500'>{address.phonenumber}</span></small>
                                    <br></br>
                                    <small class='content-color font-default mt-1'>City : <span class='title-color font-default fw-500'>{address.city}</span></small>
                                    <br></br>
                                    <small class='content-color font-default mt-1'>State : <span class='title-color font-default fw-500'>{address.state === 'Other States' ? address.otherState : address.state}</span></small>
                                    <br></br>
                                    <small class='content-color font-default mt-1'>Pincode : <span class='title-color font-default fw-500'>{address.pincode}</span></small>
                                </div>
                                <div class="btn-lft-align mt-2 mb-2">
                                    {selectedAddressId === address.id ? (
                                    <button class="btn4 btn-primary">Selected</button>
                                    ):(
                                    <button class="btn3 btn-primary" onClick={()=> handleAddressSelection(address.id)}>Select</button>
                                    )}
                                </div>
                            </div>
                            ))}
                        </div>


                    </div>
                    <div class="col-lg-5">
                      <div class="border p-40 cart-totals ml-30 mb-50">
                        <div class="d-flex align-items-end justify-content-between mb-30">
                          <h4>Your Order</h4>
                        </div>

                        <div class="divider-2 mb-30">

                        </div>

                        <div class="table-responsive order_table checkout">
                          <table class="table no-border">
                                    <tbody>
                                        <tr>
                                            <td class="cart_total_label">
                                                <h6 class="text-muted">Subtotal</h6>
                                            </td>
                                            <td class="cart_total_amount">
                                                <h4 class="text-brand text-end">₹{(parseFloat(total)-parseFloat(gst)).toFixed(2)}</h4>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="cart_total_label">
                                                <h6 class="text-muted">GST</h6>
                                            </td>
                                            <td scope="col" colspan="2">
                                                <h4 class="text-brand text-end">₹{Number(gst).toFixed(2)}</h4>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="cart_total_label">
                                                <h6 class="text-muted">Shipping</h6>
                                            </td>
                                            <td class="cart_total_amount">
                                                <h5 class="text-heading text-end">₹{Number(shipping).toFixed(2)}</h5>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="cart_total_label">
                                                <h6 class="text-muted">Coupon Discount</h6>
                                            </td>
                                            <td class="cart_total_amount">
                                                <h5 class="text-heading text-danger text-end"> ₹{Number(coupon).toFixed(2)}</h5></td>
                                        </tr>
                                        <tr>

                                        </tr>
                                        {isChecked ? (
                                        <>
                                            <tr>
                                                <td class="cart_total_label">
                                                    <h6 class="text-muted">GiftBox</h6>
                                                </td>
                                                <td class="cart_total_amount">
                                                    <h4 class="text-heading text-end">₹{Number(gift).toFixed(2)}</h4>
                                                </td>
                                            </tr>
                                            </>):(
                                            <>
                                                </>)}
                                                <tr>
                                                    <td scope="col" colspan="2">
                                                        <div class="divider-2 mt-10 mb-10"></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="cart_total_label">
                                                        <h6 class="text-muted">Total</h6>
                                                    </td>



                                                    <td class="cart_total_amount">
                                                        <h5 class="text-heading text-end">₹{Number(gtotal).toFixed(2)}</h5></td>


                                                </tr>
                                    </tbody>
                          </table>

                          <div class="checkbox">
                              <div class="custome-checkbox">
                                <input className="form-check-input" type="checkbox" name="gift" id="createaccount" checked={isChecked} onChange={handleCheckboxChange} />
                                <label class="form-check-label label_info" data-bs-toggle="collapse" href="#collapsePassword" data-target="#collapsePassword" aria-controls="collapsePassword" for="createaccount"><span>Add Gift Wrap</span></label>
                              </div>
                          </div>

                        </div>

                          {selectedAddressId === null ?(
                              <p class="text-danger mt-30 text-center">Please add/select address and then Continue to payment</p>
                          ):( 
                              <div align="center">
                                    <a class="btn btn-fill-out btn-block mt-30 w-50" onClick={processPayment}>Pay Now</a> 
                              </div>
                          )}
                        </div>
                    </div>
                </>
              )}



        </div>

        )}

    </div>
</main>

  <Footeroffline />

  </div>
</>
   )}
  </>
  )
}
export default Checkout;