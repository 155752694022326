import React, { useEffect, useState} from 'react';
import axios from 'axios';
import Header from './headerpages';
import Footeroffline from './footeroffline';

function Preorder() {
   
const [productData, setproductData] = useState(null);
const [cartItems, setCartItems] = useState([]);
const [preorder, setPreorder] = useState([]);
const [banner, setBanner] = useState('');


const fetchDatabanner = async () => {
  try {
    const response = await axios.get('https://api.arivomkadai.com/onlinebanner/');
    const datas = response.data.data.find(item => item.id === '3');
      setBanner(datas); 
  } catch (error) {   
    console.error("Error fetching data:", error);
  }
};

fetchDatabanner();


useEffect(() => {
const fetchData = async () => {
  try {
    const response = await axios.get('https://api.arivomkadai.com/live-product/products/');
    console.log(response.data);
    if(response.data.status === 'success'){
      setproductData(response.data.data);
    }
  } catch (error) {
    
    console.error("Error fetching data:", error);
  }
};

fetchData();

const intervalId = setInterval(() => {
  fetchData();
}, 5000);
return () => clearInterval(intervalId);

}, []);




const openModalsalready = () => {
  const modal = document.getElementById('copyModalsready');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
    
  }
};

const closeModalsalready = () => {
  const modal = document.getElementById('copyModalsready');
  if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
  }
};

const checkCart = (productId) => {
  const cartItems = JSON.parse(sessionStorage.getItem('cartItems'));

  if(cartItems && cartItems.length > 0){
    let userResponse = window.confirm("Your cart contains items from Regular Products. Would you like to reset your cart for adding items from Live Shopping?");
    if (userResponse) {
      sessionStorage.removeItem('cartItems');
      addToCart(productId);
    }
  }else{
    sessionStorage.removeItem('cartItems');
    addToCart(productId);
  }
};


const addToCart = async (productId) => {

  sessionStorage.removeItem('cartItems');
  const existingpreorderItems = JSON.parse(sessionStorage.getItem('preorder')) || [];
  const existingItemIndex = existingpreorderItems.findIndex(item => item.id === productId);
  const product = productData && productData.filter(items => items.id === productId);

  console.log(product);

  if (existingItemIndex !== -1) {
        openModalsalready(); 
  } else {
         
    const newItem = {
      id: productId,
      code: product[0]?.product_code,
      product: product[0]?.product_name,
      size: product[0]?.messurement,
      weight: product[0]?.weight || 0,
      quantity: 1,
      image: product[0]?.image,
      price: product[0]?.productPrice,
      mrp: product[0]?.mrp || 0,
      purchase: product[0]?.purchase || 0,
      width: product[0]?.width,
      height: product[0]?.height,
      shippingweight: product[0]?.weight,
      breadth: product[0]?.breadth,
      gst: product[0]?.productGst,
      preorder: 'no',
      vendor: product[0]?.vendor || 0,
      vendorcomission: product[0]?.vendorcomission || 0,
      hsn: product[0]?.hsn || 0,
    };

    existingpreorderItems.push(newItem);
      alert(`${product[0].product_name} added to cart!`);
    }
  
    sessionStorage.setItem('preorder', JSON.stringify(existingpreorderItems));

  };

 

  return (
     <>
  
   <div className="sidebars" >
   <a  className="sidebars-on" href="/online-products">
        <h3 className="bar-content">Online Products</h3>
        </a>
    </div>
    
<div class="main-bar">

  <Header  />

  <main class="main mt-bar">
      <section class="home-slider position-relative mb-30 mt-50" style={{minHeight:'90vh'}}>
          <div class="container">
              <div class="home-slide-cover mt-30">
                  <div class="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1">
                      <div class="single-hero-slider single-animation-wrap" style={{backgroundImage: `url(https://api.arivomkadai.com/banner_data/${banner && banner.image})`}}>
                          <div class="slider-content">
                          {productData && productData.length > 0 ? (
  
    // Render content when product exists
    <h1 className="display-1 mb-40">
     {banner && banner.heading}
    </h1>
  ) : (
    // Render "Coming soon" when product does not exist
    <h1 className="display-1 mb-40">
       Coming<br />Soon
    </h1>
  )
}



                              <p class="text-white">{banner && banner.text}</p>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
      </section>


      <div class="modal fade custom-modal" id="copyModalsready" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
         <div class="modal-dialog" style={{maxWidth: '35%'}}>
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalsalready}></button>
              <div class="modal-body bg-remover">
                <div class="login_wrap widget-taber-content background-white">
                  <div class="padding_eight_all bg-white" align="center">
                    <div class="heading_s1">
                      
                        <p class="mb-30">{productData && productData.length > 0 ? (productData && ( productData[0].product )):(<></>)}is already in cart</p>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


    
      <section class="product-tabs section-padding position-relative ">
          <div class="container">
              <div class="section-title style-2 wow animate__animated animate__fadeIn ">
                  <h3>Popular Products</h3>
              </div>
           
              <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="tab-one" role="tabpanel" aria-labelledby="tab-one">
                      <div class="row product-grid-4">
                      
                      {productData && productData.slice().reverse().map((product, indexs) => (
                          <div class="col-lg-3 col-xl-1-5 col-md-4 col-12 col-sm-6" key={product.p_id}>
                              <div class="product-cart-wrap mb-30 wow animate__animated animate__fadeIn" data-wow-delay=".1s">
                                  <div class="product-img-action-wrap">
                                      <div class="product-img product-img-zoom">
                                          <a href={`live-shopping-product?id=${product.id}`}>
                                              <img class="default-img" src={`https://api.arivomkadai.com/product_data/${product.image}`} alt={product.product} />
                                              <img class="hover-img" src={`https://api.arivomkadai.com/product_data/${product.image}`} alt={product.product} />
                                          </a>
                                      </div>
                                  </div>
                                  <div class="product-content-wrap">
                                     
                                      <h2><a href={`live-shopping-product?id=${product.id}`}>{product.product_name.length > 50 ? `${product.product_name.slice(0, 50)}...` : product.product_name}</a></h2>
                                     
                                     
                                      <div class="product-card-bottom">
                                          <div class="product-price">
                                              <span>₹ {product.productPrice}</span>
                                          </div>
                                          {/* <div class="add-cart">
                                              <a class='add'  onClick={() => checkCart(product.id)}  ><i class="fi-rs-shopping-cart mr-5"></i>Add Cart </a>
                                          </div> */}

                                          <div class="add-cart">
                                              <a class='add' href={`live-shopping-product?id=${product.id}`}  ><i class="fi-rs-shopping-cart mr-5"></i>Buy Now</a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      
                          ))}
                        
                      </div>
                
                  </div>
                 
              </div>
           
          </div>
      </section>
     
  </main>

    <Footeroffline />

    </div>
    </>
  )
}
export default  Preorder;

